<template>
  <div id="comparison">
    <figure :style="{ backgroundImage: 'url(' + images[1] + ')' }">
      <div
        :style="{ backgroundImage: 'url(' + images[0] + ')' }"
        ref="divisor"
        class="divisor"
      ></div>
    </figure>
    <input
      type="range"
      min="0"
      max="100"
      value="50"
      id="slider"
      ref="slider"
      @input="moveDivisor"
    />
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: ["images"],
  setup(props) {
    let divisor = ref(null);
    let slider = ref(null);
    let before = "url(" + props.images[0] + ")";
    let after = "url(" + props.images[1] + ")";
    function moveDivisor() {
      divisor.value.style.width = slider.value.value + "%";
    }

    return {
      before,
      after,
      divisor,
      slider,
      moveDivisor,
    };
  },
};
</script>

<style lang="scss" scoped>
div#comparison {
  width: 100%;
  height: 400px;
  max-width: 100%;
  max-height: 400px;
  overflow: hidden;
  border-radius: 8px;
}
div#comparison figure {
  background-size: cover;
  position: relative;
  font-size: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
div#comparison figure > img {
  position: relative;
  width: 100%;
}
div#comparison figure div {
  background-size: cover;
  position: absolute;
  width: 50%;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  bottom: 0;
  height: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: -2rem;
  left: -2%;
  background-color: rgba(255, 255, 255, 0.1);
  width: 102%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:active {
  outline: none;
}

input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 15px;
  width: 98%;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  outline: none;
}
input[type="range"]::active {
  border: none;
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 15px;
  background: #fff;
  border-radius: 0;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  width: 20px;
  height: 15px;
  background: #fff;
  border-radius: 0;
}
input[type="range"]:focus::-webkit-slider-thumb {
  background: rgba(255, 255, 255, 0.5);
}
input[type="range"]:focus::-moz-range-thumb {
  background: rgba(255, 255, 255, 0.5);
}
</style>
