<template>
  <div style="background-color: #f9f8fd" class="p-4">
    <div id="msg-zone">
      <div>
        <div v-for="one in comments" :key="one.id" class="flex flex-column">
          <div class="mb-5" :class="{ 'align-self-end': isMe(one.From.id) }">
            <span class="text-xs ml-2" :class="{ 'align-self-end': isMe(one.From.id) }"
              >{{ one.From.first_name }} {{ one.From.last_name }}</span
            >
            <div
              v-if="one.Comment != '0'"
              class="msg flex flex-column"
              :style="{
                'background-color': isMe(one.From.id) ? '#6366F1' : 'white',
                color: isMe(one.From.id) ? 'white' : '',
                'border-bottom-left-radius': isMe(one.From.id) ? '10px' : '2px',
                'border-bottom-right-radius': isMe(one.From.id) ? '2px' : '10px',
              }"
            >
              <span
                class="text-base font-medium"
                style="white-space: pre-wrap; word-break: break-all"
                >{{ one.Comment }}</span
              >
              <span class="text-xs align-self-end mt-2">{{
                formatDateToHour(one.CreatedAt)
              }}</span>
            </div>
            <div v-for="file in one.Attachments" :key="file.id">
              <div
                v-if="file.directus_files_id.type.split('/')[0] === 'application'"
                class="flex align-items-center cursor-pointer file-attach"
                @click="viewFile(file)"
              >
                <Avatar
                  icon="pi pi-file"
                  class="mr-2"
                  style="background-color: rgb(199 214 246); color: #535cc1"
                />
                <div class="flex flex-column">
                  <span class="text-sm font-semibold">{{
                    file.directus_files_id.filename_download
                  }}</span>
                  <span class="text-sm">{{
                    convertSize(file.directus_files_id.filesize)
                  }}</span>
                </div>
              </div>
              <img
                v-else
                class="image-attach cursor-pointer"
                :src="`/core/data/assets/${file.directus_files_id.id}?height=60`"
                @click="viewFile(file)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <Divider />
      <div class="grid">
        <div class="col-10">
          <!-- <Textarea
            
            placeholder="Write a message..."
            :autoResize="true"
            rows="1"
            class="w-full"
          /> -->
          <Textarea
            v-model="comment"
            id="firstname1"
            :autoResize="true"
            rows="1"
            @change="test()"
            placeholder="Write a message..."
            class="text-base text-color w-full outline-none border-none"
          />
          <div class="mt-4 flex flex-wrap">
            <div class="p-2" v-for="(img, index) in imageFiles" :key="index">
              <div
                class="relative"
                @mouseenter="cancelIndexImage = index"
                @mouseleave="cancelIndexImage = null"
              >
                <img class="image-sending" :src="img.raw" />
                <Icon
                  v-if="cancelIndexImage === index"
                  icon="ic:round-cancel"
                  color="#424242"
                  width="24"
                  height="24"
                  class="absolute cancel cursor-pointer"
                  style="top: 2px; right: 2px"
                  @click="imageFiles.splice(index, 1)"
                />
              </div>
            </div>
            <div class="p-2" v-for="(file, index) in files" :key="index">
              <div
                class="relative"
                @mouseenter="cancelIndexFile = index"
                @mouseleave="cancelIndexFile = null"
              >
                <div
                  v-if="file.type.split('/')[0] === 'application'"
                  class="flex align-items-center cursor-pointer file-attach py-3"
                  @click="viewFile(file)"
                >
                  <Avatar
                    icon="pi pi-file"
                    class="mr-2"
                    size="large"
                    style="background-color: rgb(199 214 246); color: #535cc1"
                  />
                  <div class="flex flex-column">
                    <span class="text-sm font-semibold">{{ file.name }}</span>
                    <span class="text-sm">{{ convertSize(file.size) }}</span>
                  </div>
                </div>
                <Icon
                  v-if="cancelIndexFile === index"
                  icon="ic:round-cancel"
                  color="#424242"
                  width="24"
                  height="24"
                  class="absolute cancel cursor-pointer"
                  style="top: 4px; right: 4px"
                  @click="files.splice(index, 1)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <Button
            class="p-button-secondary p-button-text"
            icon="pi pi-refresh"
            @click="loadComments"
          />
          <Button
            class="mr-2 p-button-secondary p-button-text"
            icon="pi pi-paperclip"
            :loading="isSending"
            @click="importData"
          />

          <Button class="mr-2" icon="pi pi-send" @click="send" :loading="isSending" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useCommentService } from "../../../../compositions/services/useCommentService";
import { useToast } from "primevue/usetoast";

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const isSending = ref(false);
    const comment = ref("");
    const comments = ref(null);
    const cancelIndexImage = ref(null);
    const cancelIndexFile = ref(null);
    const historyDate = ref(null);
    const imageFiles = ref([]);
    const files = ref([]);
    const messageCount = ref(null);

    const { getComments, isMe, addComment } = useCommentService();

    const viewFile = (file) => {
      window.open(`/core/data/assets/${file.directus_files_id.id}`, "_blank");
    };

    const intervalId = setInterval(function () {
      getComments(props.project.id)
        .then(({ data }) => {
          comments.value = data.Comments;
          if (messageCount.value < comments.value.length) {
            toast.add({
              severity: "success",
              summary: "New message",
              life: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 30000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    const send = () => {
      let filesToSend = files.value;
      filesToSend = filesToSend.concat(imageFiles.value.map((one) => one.file));

      if (!comment.value && !filesToSend) {
        return;
      }
      console.log(comment.value);
      console.log(filesToSend);
      isSending.value = true;
      addComment(props.project.HortusCode, {
        Comment: comment.value || "",
        Attachments: filesToSend,
      })
        .then(() => {
          files.value = [];
          imageFiles.value = [];
          comment.value = "";
          messageCount.value++;
          loadComments();
        })
        .finally(() => {
          isSending.value = false;
        });
    };

    const loadComments = () => {
      getComments(props.project.id)
        .then(({ data }) => {
          comments.value = data.Comments;
          messageCount.value = data.Comments.length;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    function formatDateToHour(value) {
      const date = new Date(value);
      const def = date.toLocaleTimeString("default", {
        hour: "2-digit",
        minute: "2-digit",
      });
      return def;
    }

    function formatedDate(value) {
      const date = new Date(value);
      const def = date.toDateString("default", {
        day: "2-digit",
        month: "3-digit",
        year: "4-digit",
      });

      const last = historyDate.value;
      historyDate.value = def;

      if (last === null) {
        return def;
      } else if (def === last) {
        return null;
      }
      return def;
    }

    function convertSize(size) {
      if (size * 0.001 < 1000) {
        return (size * 0.001).toFixed(2) + " KB";
      }
    }

    function importData() {
      let input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.onchange = () => {
        // you can use this method to get file and perform respective operations
        let currentFiles = Array.from(input.files);
        currentFiles.forEach((element) => {
          if (element.type.split("/")[0] === "application") {
            files.value.push(element);
          } else {
            const reader = new FileReader();
            reader.addEventListener(
              "load",
              function () {
                // convert image file to base64 string
                imageFiles.value.push({
                  file: element,
                  raw: reader.result,
                });
              },
              false
            );

            if (element) {
              reader.readAsDataURL(element);
            }
          }
        });
      };
      input.click();
    }

    onMounted(() => {
      loadComments();
      document.getElementById("msg-zone").scrollTop = 500;
    });

    function test() {}
    return {
      comment,
      comments,
      isSending,
      historyDate,
      imageFiles,
      files,
      test,
      toast,
      cancelIndexImage,
      cancelIndexFile,
      convertSize,
      formatDateToHour,
      formatedDate,
      loadComments,
      importData,
      isMe,
      viewFile,
      send,
      messageCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.comment {
  max-width: 512px;
}
.comment-owner {
}
#msg-zone {
  height: 500px;
  overflow-y: scroll;
  padding: 0 150px;
  .msg {
    padding: 15px;
    width: fit-content;
    max-width: 400px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 14px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.image-attach {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.image-sending {
  width: 70px;
  height: 70px;

  border-radius: 8px;
  object-fit: cover;
}

.file-attach {
  padding: 12px 10px;
  background-color: #ebedefaf;
  width: fit-content;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 10px;
}
</style>
