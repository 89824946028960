import http from '../../services/http'
import {
  useAuth
} from '../useAuth'

const {
  user
} = useAuth()

const getComments = async function (id) {
  const {
    data
  } = await http.core.get(`/data/items/Hortus_Projects/${id}?fields=*,Comments.*,Comments.From.*,Comments.Attachments.*,Comments.Attachments.directus_files_id.*`)
  return data
}

const isMe = function (id) {
  return user.value.user.id === id
}

const addComment = async function (projectCode, data, wipCb) {
  let body = new FormData()
  body.append('comment', data.Comment)
  let files = data.Attachments || []
  files.forEach(one => {
    body.append('files', one)
  })

  const progressCb = wipCb || function () {}

  return http.core.post(`/api/projects/${projectCode}/comments`, body, {
    onUploadProgress: event => {
      console.log(event)
      progressCb(event)
    }
  })

  // const { data } = await http.core.post(`/api/projects/${projectCode}/comments`, body)
  // return data
}

export function useCommentService() {
  return {
    isMe,
    getComments,
    addComment
  }
}