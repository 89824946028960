import http from '../../services/http'

const version = 1

const updatePlantInventory = async function (projectId, bookId, list) {
  return http.core.put(`/api/projects/${projectId}/hortus-book/${bookId}/plants-inventory`, {
    list
  })
}

const updateMaterialInventory = async function (projectId, bookId, list) {
  return http.core.put(`/api/projects/${projectId}/hortus-book/${bookId}/materials-inventory`, {
    list
  })
}

const getPlantInventory = async function () {
  return http.core.get(`data/items/Hortus_Books_Plants_Shopping_List`)
}

// ////////////////////////////////////////////////////////////////////////////
// Printer
const getHortusBookUrl =  function (projectCode, bookId) {
  return http.getApiUrl(`/api/projects/${projectCode}/hortus-book/${bookId}/pdf`)
}


// ////////////////////////////////////////////////////////////////////////////
// Plans
const addPlansPage = async function (projectCode, bookId) {
  return http.core.post(`/api/projects/${projectCode}/hortus-book/${bookId}/plans/pages`)
}

const getPlansFromProject = function (project, version) {
  const currentBook = version - 1
  let plans = []

  try { plans = JSON.parse(JSON.stringify(project.HortusBooks[currentBook].Plans)) } catch (e) { e }

  return plans
}

const updatePlansPageByUpload = async function (projectCode, bookId, pageId, file, wipCb) {
  let body = new FormData()
  body.append('file', file)

  const progressCb = wipCb || function () {}

  return http.core.put(`/api/projects/${projectCode}/hortus-book/${bookId}/plans/pages/${pageId}/media/by-upload`, body, {
    onUploadProgress: event => {
      progressCb(event)
    }
  })
}

// ////////////////////////////////////////////////////////////////////////////
// Before After
const addBeforeAfterPage = async function (projectCode, bookId) {
  return http.core.post(`/api/projects/${projectCode}/hortus-book/${bookId}/before-after/pages`)
}

const updateBeforeAfterPageByFileId = async function (projectCode, bookId, pageId, which, fileId) {
  const body = {
    which,
    fileId
  }
  return http.core.put(`/api/projects/${projectCode}/hortus-book/${bookId}/before-after/pages/${pageId}/media/by-fileId`, body)
}

const updateBeforeAfterPageByUpload = async function (projectCode, bookId, pageId, which, file, wipCb) {
  let body = new FormData()
  body.append('which', which)
  body.append('file', file)

  const progressCb = wipCb || function () {}

  return http.core.put(`/api/projects/${projectCode}/hortus-book/${bookId}/before-after/pages/${pageId}/media/by-upload`, body, {
    onUploadProgress: event => {
      progressCb(event)
    }
  })
}

const getBeforeAfterFromProject = function (project, version) {

  const currentBook = version - 1

  if (project.HortusBooks &&
    project.HortusBooks.length >= currentBook &&
    project.HortusBooks[currentBook].BeforeAfter) {
    return JSON.parse(JSON.stringify(project.HortusBooks[currentBook].BeforeAfter))
  }

  return []
}

export function useBookService() {
  return {
    version,
    updatePlantInventory,
    updateMaterialInventory,
    getPlantInventory,

    addBeforeAfterPage,
    updateBeforeAfterPageByFileId,
    updateBeforeAfterPageByUpload,
    getBeforeAfterFromProject,

    addPlansPage,
    getPlansFromProject,
    updatePlansPageByUpload,

    getHortusBookUrl
  }
}