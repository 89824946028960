<template>
  <div class="p-4">
    <div class="pt-2">
      <div class="flex flex-row justify-content-between align-items-center">
        <div>
          <div class="text-2xl mb-2 font-semibold">Plants and materials</div>
          <span class="text-base"
            >Elements that should be buy by the client with the quantity for each
            element</span
          >
          <br />
          <Button
            class="mt-4"
            label="Overview"
            icon="pi pi-window-maximize"
            iconPos="right"
            @click="openFull"
          />
          <Sidebar v-model:visible="visibleFull" :baseZIndex="10000" position="full">
            <div class="flex flex-row justify-content-between align-items-center mb-3">
              <div class="search-bar">
                <i class="pi pi-search"></i>
                <input
                  v-model="searchValue"
                  type="text"
                  class="input"
                  placeholder="&nbsp;"
                />
                <span class="label">Search</span>
                <Switcher
                  ref="selectedMood"
                  :option="optionMood"
                  :label="['All', 'Moods']"
                ></Switcher>
              </div>

              <Switcher
                ref="selectedType"
                :option="optionType"
                :label="['Plants', 'Materials']"
              ></Switcher>
            </div>
            <div class="images-box">
              <div
                v-for="(image, index) in searchAvailable"
                :key="index"
                :class="
                  image.Varieties && image.Varieties.length > 0 && image.isSelected
                    ? 'subgrid surface-200 mt-6'
                    : ''
                "
              >
                <div class="item shadow-2 cursor-pointer" @click="imageClick(image)">
                  <div
                    class="item__image"
                    v-if="
                      !(image.Varieties && image.Varieties.length > 0 && image.isSelected)
                    "
                  >
                    <img
                      v-if="selectedType.model === 'optPlants'"
                      v-lazy
                      :alt="image.LatinName"
                      class="mr-2 mb-2"
                      :data-src="getPreview(image.Preview, 210)"
                      src="../../../../assets/img/placeholder.gif"
                    />
                    <img
                      v-else
                      v-lazy
                      :alt="image.Name"
                      class="mr-2 mb-2"
                      :data-src="getPreview(image.Preview, 210)"
                      src="../../../../assets/img/placeholder.gif"
                    />
                  </div>
                  <div class="p-3">
                    <span
                      v-if="selectedType.model === 'optPlants'"
                      class="item__title text-xl font-semibold"
                      >{{ image.LatinName }}</span
                    >
                    <span v-else class="item__title text-xl font-semibold">{{
                      image.Name
                    }}</span>
                    <p class="item__content flex flex-row">
                      <span>{{ image.Reference || i18n.$t(image.Type) }}</span>

                      <span v-if="image.Varieties && image.Varieties.length > 0">
                        <i class="pi pi-plus-circle"></i>
                        {{ image.Varieties.length }} Varieties
                      </span>
                    </p>
                  </div>
                </div>
                <transition
                  v-if="image.Varieties && image.Varieties.length > 0 && image.isSelected"
                  leave-active-class="animate__animated animate__slideOutUp"
                  enter-active-class="animate__animated animate__slideOutDown"
                  mode="out-in"
                >
                  <div style="padding-left: 150px" class="my-5">
                    <div class="images-box">
                      <div
                        style="width: 100%"
                        v-for="(child, index) in image.Varieties"
                        :key="index"
                      >
                        <div
                          class="item shadow-2 cursor-pointer"
                          @click="imageClick(child.related_Inventaire_Plants_id)"
                        >
                          <div class="item__image">
                            <img
                              v-if="selectedType.model === 'optPlants'"
                              v-lazy
                              :alt="child.related_Inventaire_Plants_id.LatinName"
                              class="mr-2 mb-2"
                              :data-src="
                                getPreview(
                                  child.related_Inventaire_Plants_id.Preview,
                                  210
                                )
                              "
                              src="../../../../assets/img/placeholder.gif"
                            />
                            <img
                              v-else
                              v-lazy
                              :alt="child.related_Inventaire_Plants_id.Name"
                              class="mr-2 mb-2"
                              :data-src="
                                getPreview(
                                  child.related_Inventaire_Plants_id.Preview,
                                  210
                                )
                              "
                              src="../../../../assets/img/placeholder.gif"
                            />
                          </div>
                          <div class="p-3">
                            <span
                              v-if="selectedType.model === 'optPlants'"
                              class="item__title text-xl font-semibold"
                              >{{ child.related_Inventaire_Plants_id.LatinName }}</span
                            >
                            <span v-else class="item__title text-xl font-semibold">{{
                              child.related_Inventaire_Plants_id.Name
                            }}</span>
                            <p class="item__content flex flex-row">
                              <span>{{
                                child.related_Inventaire_Plants_id.Reference ||
                                i18n.$t(child.related_Inventaire_Plants_id.Type)
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>

            <Sidebar v-model:visible="visiblePlant" class="p-sidebar-md" position="right">
              <div v-if="currentPlant">
                <div class="m-3 mb-5 sidebar">
                  <div class="relative">
                    <Image
                      :src="getPreview(currentPlant.Preview)"
                      style="width: 100%"
                      alt="Image"
                      preview
                    />
                    <ToggleButton
                      style="width: 200px; bottom: 4px; right: 8px"
                      class="mb-2 absolute"
                      v-model="currentPlant.isSelected"
                      onLabel="Selected"
                      offLabel="Unselected"
                      onIcon="pi pi-check"
                      offIcon="pi pi-times"
                      aria-label="do you select"
                      @click="mooved(currentPlant)"
                    />
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon icon="tabler:plant-2" class="mr-3" width="24px" height="24px" />
                    <div>
                      <span>{{ currentPlant.LatinName }}</span
                      ><br />
                      <span class="text-sm font-semibold">{{ currentPlant.Name }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon icon="lucide:type" class="mr-3" width="24px" height="24px" />
                    <div>
                      <span class="text-sm font-semibold">{{ i18n.$t("type") }}</span
                      ><br />
                      <span>{{ i18n.$t(currentPlant.Type) }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon icon="bi:box" class="mr-3" width="24px" height="24px" />
                    <div>
                      <span class="text-sm font-semibold">{{
                        i18n.$t("flower_form")
                      }}</span
                      ><br />
                      <span>{{ i18n.$t(currentPlant.FlowerForm) }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon
                      icon="carbon:soil-moisture"
                      class="mr-3"
                      width="24px"
                      height="24px"
                    />
                    <div>
                      <span class="text-sm font-semibold mb-1">{{
                        i18n.$t("soil_type")
                      }}</span
                      ><br />
                      <Chip
                        class="mr-2 mb-2"
                        v-for="(t, index) in currentPlant.SoilType"
                        :key="index"
                      >
                        <span class="text-sm my-1">{{ i18n.$t(t) }}</span>
                      </Chip>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon icon="mdi:ph" class="mr-3" width="24px" height="24px" />
                    <div>
                      <span class="text-sm font-semibold mb-1">{{
                        i18n.$t("soil_ph")
                      }}</span
                      ><br />
                      <Chip
                        class="mr-2 mb-2"
                        v-for="(t, index) in currentPlant.SoilPh"
                        :key="index"
                      >
                        <span class="text-sm my-1">{{ i18n.$t(t) }}</span>
                      </Chip>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon icon="bi:moisture" class="mr-3" width="24px" height="24px" />
                    <div>
                      <span class="text-sm font-semibold">{{
                        i18n.$t("soil_moisture")
                      }}</span
                      ><br />
                      <Chip
                        class="mr-2"
                        v-for="(t, index) in currentPlant.SoilMoisture"
                        :key="index"
                      >
                        <span class="text-sm my-1">{{ i18n.$t(t) }}</span>
                      </Chip>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon
                      icon="emojione-monotone:blossom"
                      class="mr-3"
                      width="24px"
                      height="24px"
                    />
                  </div>
                </div>
                <div class="flex justify-content-around mx-3 mb-4">
                  <div
                    class="flex flex-column align-items-center"
                    v-for="s in currentPlant.SunExposure"
                    :key="s"
                  >
                    <Icon
                      v-if="s === 'full-sun'"
                      icon="emojione:sun"
                      width="20"
                      height="20"
                    />
                    <Icon
                      v-if="s === 'partial-shade'"
                      icon="emojione-v1:sun-behind-cloud"
                      width="20"
                      height="20"
                    />
                    <Icon
                      v-if="s === 'full-shade'"
                      icon="emojione-v1:cloud"
                      width="20"
                      height="20"
                    />
                    <span class="text-sm">{{ i18n.$t(s) }}</span>
                  </div>
                </div>
              </div>
            </Sidebar>
            <Sidebar
              v-model:visible="visibleMaterial"
              class="p-sidebar-md"
              position="right"
            >
              <div v-if="currentMaterial">
                <div class="flex flex-column m-3 mb-5 sidebar">
                  <div class="relative">
                    <Image
                      :src="getPreview(currentMaterial.Preview)"
                      style="width: 100%"
                      alt="Image"
                      preview
                    />
                    <ToggleButton
                      style="width: 200px; bottom: 4px; right: 8px"
                      class="mb-2 absolute"
                      v-model="currentMaterial.isSelected"
                      onLabel="Selected"
                      offLabel="Unselected"
                      onIcon="pi pi-check"
                      offIcon="pi pi-times"
                      aria-label="do you select"
                      @click="mooved(currentMaterial)"
                    />
                  </div>
                </div>
                <div class="flex flex-column ml-5 mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon
                      icon="akar-icons:link-chain"
                      class="mr-3"
                      width="24px"
                      height="24px"
                    />
                    <div class="flex flex-row align-items-center">
                      <h6
                        class="text-lg mb-0 truncate"
                        target="_blank"
                        :href="currentMaterial.Link"
                      >
                        {{ currentMaterial.Name }}
                      </h6>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="flex flex-column ml-5 mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon icon="bi:box" class="mr-3" width="24px" height="24px" />
                    <div>
                      <span v-for="mood in currentMaterial.Moods" v-bind:key="mood">
                        <Chip :label="mood.Moods_id.Name" class="mr-2 mb-2"></Chip>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column ml-5 mb-4">
                  <div class="flex flex-row align-items-center flex-wrap">
                    <Icon
                      icon="entypo:price-tag"
                      class="mr-3"
                      width="24px"
                      height="24px"
                    />
                    <div>
                      <span class="text-base mb-1"
                        >{{ parseFloat(currentMaterial.Price).toFixed(2) }} €</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </Sidebar>
          </Sidebar>
        </div>
        <div class="flex w-full justify-content-end">
          <Dropdown
            class="my-3"
            v-model="type"
            :options="KViewList"
            optionLabel="label"
            optionValue="id"
            style="width: 200px"
          />
        </div>
      </div>

      <Divider />
    </div>

    <div class="mt-6 picklist">
      <Panel header="Selected">
        <template #icons>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="selectedSearch" :placeholder="i18n.$t('search')" />
          </span>
          <Button
            class="mx-2"
            type="button"
            icon="pi pi-trash"
            @click="unSelect"
            v-if="type === 'Plants:mood' ? showDelete[0] : showDelete[1]"
          />
        </template>
        <div
          class="flex flex-column align-items-center relative"
          v-if="searchPlants.length === 0"
        >
          <img src="../../../../assets/img/nodata.png" class="mx-auto" width="300" />
          <p class="absolute text-xl" style="top: 240px">No element selected yet...</p>
        </div>
        <div v-else>
          <div v-for="(item, index) in searchPlants" :key="index">
            <div class="flex flex-row justify-content-between align-items-center">
              <div class="flex flex-row align-items-center">
                <Checkbox
                  class="mr-2"
                  :inputId="item.Name"
                  v-model="item.unSelection"
                  @change="selectChange"
                  :binary="true"
                />
                <label :for="item.Name"></label>
                <div>
                  <img
                    v-lazy
                    v-if="type === 'Plants:mood'"
                    :alt="item.LatinName"
                    class="border-round"
                    :data-src="getPreview(item.Preview)"
                    src="../../../../assets/img/placeholder.gif"
                    width="50"
                    height="50"
                  />
                  <img
                    v-lazy
                    v-if="type === 'materials:mood'"
                    :alt="item.Name"
                    class="border-round"
                    :data-src="getPreview(item.Preview)"
                    src="../../../../assets/img/placeholder.gif"
                    width="50"
                    height="50"
                  />
                  <!-- <Image :src="getPreview(item.Preview, 50)" alt="Image" /> -->
                </div>
                <div class="flex flex-column ml-2 my-2">
                  <span class="text-base font-semibold title">
                    {{ item.LatinName || item.Name }}
                  </span>
                  <span class="text-sm">
                    {{ item.Reference || i18n.$t(item.Type) }}
                  </span>
                </div>
              </div>

              <div style="width: 120px">
                <InputNumber
                  id="vertical"
                  @focusout="quantityChange()"
                  v-model="item.quantity"
                  mode="decimal"
                  :min="0"
                  showButtons
                  buttonLayout="horizontal"
                  style="width: 4rem"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                />
              </div>
            </div>
            <Divider />
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import "animate.css";
import { ref, computed } from "vue";
import { useToast } from "primevue/usetoast";
import usePreview from "../../../../compositions/usePreview";
import { useI18n } from "../../../../i18nPlugin";
import Switcher from "../Switcher.vue";
import { useProjectService } from "../../../../compositions/services/useProjectService";
import { usePlantService } from "../../../../compositions/services/usePlantService";
import { useMaterialService } from "../../../../compositions/services/useMaterialService";
import { useBookService } from "../../../../compositions/services/useBookService";

const KViewListPlants = "Plants";
const KViewListPlantsMood = `${KViewListPlants}:mood`;
// const KViewListPlantsAll = `${KViewListPlants}:all`
const KViewListMaterials = "materials";
const KViewListMaterialsMood = `${KViewListMaterials}:mood`;
// const KViewListMaterialsAll = `${KViewListMaterials}:all`

const KViewList = [
  { id: KViewListPlantsMood, label: "Mood Plants" },
  // { id: KViewListPlantsAll, label: 'All Plants' },
  { id: KViewListMaterialsMood, label: "Mood Materials" },
  // { id: KViewListMaterialsAll, label: 'All Materials' }
];

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: { Switcher },
  setup(props) {
    const plantStore = ref([[], []]);
    const oldPlantStore = ref([[], []]);
    const materialStore = ref([[], []]);
    const oldMaterialStore = ref([[], []]);
    const darktableListPlant = ref([]);
    const darktableListMaterial = ref([]);
    const i18n = useI18n();
    const quantity = ref(0);
    const visibleFull = ref(false);
    const selection = ref([]);
    const toast = useToast();
    const { getPreview } = usePreview();
    const availableSearch = ref("");
    const selectedSearch = ref("");
    const visiblePlant = ref(false);
    const visibleMaterial = ref(false);
    const currentPlant = ref(null);
    const currentMaterial = ref(null);
    const optionType = ref("optPlants");
    const optionMood = ref("optAll");
    const selectedType = ref("");
    const selectedMood = ref("");
    const allPlants = ref([]);
    const allMaterials = ref([]);
    const searchValue = ref("");
    const showDelete = ref([false, false]);
    const type = ref(KViewListPlantsMood);
    const typeDarktable = ref(KViewListPlantsMood);
    const { getPlantsByMoods } = useProjectService();
    const { getValueSearch } = usePlantService();
    const { getMaterialsByMoods, getValueSearchMaterial } = useMaterialService();
    const { updatePlantInventory, updateMaterialInventory } = useBookService();

    const bookVersion = 0;
    function test(value) {
      console.log(value);
    }

    function quantityChange() {
      if (type.value.startsWith(KViewListPlants)) {
        updatePlantInventory(
          props.project.HortusCode,
          bookVersion + 1,
          plantStore.value[1].map((one) => ({
            id: one.id,
            quantity: one.quantity,
          }))
        );
      } else {
        updateMaterialInventory(
          props.project.HortusCode,
          bookVersion + 1,
          materialStore.value[1].map((one) => ({
            id: one.id,
            quantity: one.quantity,
          }))
        );
      }
    }

    function mooved(item) {
      if (item !== undefined) {
        item.quantity = 1;

        isSelected(item.id);
        console.log(item.isSelected);

        if (
          visibleFull.value
            ? selectedType.value.model === "optPlants"
            : type.value.startsWith(KViewListPlants)
        ) {
          item.isSelected
            ? plantStore.value[1].push(item)
            : plantStore.value[1].find(function (el, index) {
                console.log(el);
                if (el !== undefined && item.id === el.id) {
                  plantStore.value[1].splice(index, 1);
                }
              });
          if (item.isSelected) {
            item.unSelection = false;
          }
          console.log(item);
          updatePlantInventory(
            props.project.HortusCode,
            bookVersion + 1,
            plantStore.value[1].map((one) => ({
              id: one.id,
              quantity: one.quantity,
            }))
          );
        } else {
          item.isSelected
            ? materialStore.value[1].push(item)
            : materialStore.value[1].find(function (el, index) {
                if (item.id === el.id) {
                  materialStore.value[1].splice(index, 1);
                }
              });

          updateMaterialInventory(
            props.project.HortusCode,
            bookVersion + 1,
            materialStore.value[1].map((one) => ({
              id: one.id,
              quantity: one.quantity,
            }))
          );
        }
      }
    }

    function isSelected(id) {
      if (
        selectedType.value.model === "optPlants" &&
        selectedMood.value.model === "optAll"
      ) {
        allPlants.value.find(function (item) {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        });
      } else if (
        selectedType.value.model === "optPlants" &&
        selectedMood.value.model !== "optAll"
      ) {
        darktableListPlant.value.find(function (item) {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        });
      } else if (
        selectedType.value.model !== "optPlants" &&
        selectedMood.value.model === "optAll"
      ) {
        allMaterials.value.find(function (item) {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        });
      } else {
        darktableListMaterial.value.find(function (item) {
          if (item.id === id) {
            item.isSelected = !item.isSelected;
          }
        });
      }
    }

    function goTo(url) {
      if (!url) return;
      window.open(url, "_blank");
    }

    if (props.project.Mood) {
      getPlantsByMoods(props.project.Mood.id, props.project.Mood.Links)
        .then(({ data }) => {
          const availableList = [];
          const selectedList = [];
          const bySelected = {};
          try {
            props.project.HortusBooks[bookVersion].PlantsShoppingList.forEach((one) => {
              bySelected[one.Plant.id] = one.Quantity;
              one.Plant.quantity = bySelected[one.Plant.id];
              one.Plant.isSelected = true;
              one.Plant.unSelection = false;

              selectedList.push(one.Plant);
            });
          } catch (e) {
            e;
          }

          data.forEach((one) => {
            one.isSelected = false;
            one.quantity = 1;
            availableList.push(one);
            darktableListPlant.value.push(one);
          });

          plantStore.value = [availableList, selectedList];
        })
        .catch((err) =>
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          })
        );

      getMaterialsByMoods(props.project.Mood.id, props.project.Mood.Links)
        .then(({ data }) => {
          const availableList = [];
          const selectedList = [];

          const bySelected = {};
          try {
            props.project.HortusBooks[bookVersion].MaterialsShoppingList.forEach(
              (one) => {
                bySelected[one.Item.id] = one.Quantity;
                one.Item.quantity = bySelected[one.Item.id];
                one.Item.isSelected = true;
                one.Item.unSelection = false;
                selectedList.push(one.Item);
              }
            );
          } catch (e) {
            e;
          }

          data.forEach((one) => {
            one.isSelected = false;
            one.quantity = 1;
            availableList.push(one);
            darktableListMaterial.value.push(one);
          });

          materialStore.value = [availableList, selectedList];
        })
        .catch((e) => {
          console.log(e);
        });
    }

    const searchPlants = computed({
      get() {
        if (type.value.startsWith(KViewListPlants)) {
          return plantStore.value[1].filter((element) =>
            element.LatinName.toLowerCase().includes(selectedSearch.value.toLowerCase())
          );
        } else {
          return materialStore.value[1].filter((element) =>
            element.Name.toLowerCase().includes(selectedSearch.value.toLowerCase())
          );
        }
      },
      set(value) {
        if (type.value.startsWith(KViewListPlants)) {
          plantStore.value[1] = value;
        } else {
          materialStore.value[1] = value;
        }
      },
    });

    function imageClick(item) {
      console.log(item);
      if (item.Varieties && item.Varieties.length > 0) {
        item.isSelected = !item.isSelected;
      } else {
        if (item.LatinName !== undefined) {
          visiblePlant.value = true;
          visibleMaterial.value = false;
          currentMaterial.value = null;
          currentPlant.value = item;
        } else {
          visiblePlant.value = false;
          visibleMaterial.value = true;
          currentMaterial.value = item;
          currentPlant.value = null;
        }
      }
    }

    function search(search) {
      if (selectedType.value.model === "optPlants") {
        getValueSearch(search)
          .then((data) => {
            let result = data.data;
            if (allPlants.value.length === 0) {
              plantStore.value[1].forEach((item) => {
                result.find(function (el) {
                  if (item.id === el.id) {
                    el.isSelected = true;
                    el.unSelection = false;
                  }
                });
              });
            } else {
              result.forEach((item) => {
                allPlants.value.find(function (el) {
                  if (item.id === el.id) {
                    item.isSelected = el.isSelected;
                    item.unSelection = el.unSelection;
                  }
                });
              });
            }
            result.forEach((item) => {
              if (item.isSelected === undefined) {
                item.isSelected = false;
                item.unSelection = false;
              }
            });
            allPlants.value = result;
          })
          .catch((err) => {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err.message,
              life: "3000",
            });
          });
      } else {
        getValueSearchMaterial(search)
          .then((data) => {
            allMaterials.value = data.data;
            materialStore.value[1].forEach((item) => {
              allMaterials.value.find(function (el) {
                if (item.id === el.id) {
                  el.isSelected = true;
                  el.unSelection = false;
                } else {
                  el.isSelected = false;
                }
              });
            });
            allMaterials.value.find(function (el) {
              if (el.isSelected === undefined) {
                el.isSelected = false;
              }
            });
          })
          .catch((err) => {
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err.message,
              life: "3000",
            });
          });
      }
    }

    const searchAvailable = computed({
      get() {
        if (selectedType.value) {
          if (
            selectedType.value.model === "optPlants" &&
            selectedMood.value.model === "optAll"
          ) {
            search(searchValue.value);
            return allPlants.value;
          } else if (
            selectedType.value.model === "optPlants" &&
            selectedMood.value.model !== "optAll"
          ) {
            return darktableListPlant.value.filter((element) =>
              element.LatinName.toLowerCase().includes(searchValue.value.toLowerCase())
            );
          } else if (
            selectedType.value.model !== "optPlants" &&
            selectedMood.value.model === "optAll"
          ) {
            search(searchValue.value);
            return allMaterials.value;
          } else {
            return darktableListMaterial.value.filter((element) =>
              element.Name.toLowerCase().includes(searchValue.value.toLowerCase())
            );
          }
        } else {
          return type.value.startsWith(KViewListPlants)
            ? plantStore.value[0]
            : materialStore.value[0];
        }
      },
      set(value) {
        if (
          selectedType.value.model === "optPlants" &&
          selectedMood.value.model === "optAll"
        ) {
          allPlants.value = value;
        } else if (
          selectedType.value.model === "optPlants" &&
          selectedMood.value.model !== "optAll"
        ) {
          darktableListPlant.value = value;
        } else if (
          selectedType.value.model !== "optPlants" &&
          selectedMood.value.model === "optAll"
        ) {
          allMaterials.value = value;
        } else {
          darktableListMaterial.value = value;
        }
      },
    });

    function openFull() {
      visibleFull.value = true;
      selectedType.value = "optPlants";
      selectedMood.value = "optAll";
    }
    function selectChange() {
      showDelete.value[0] = false;
      showDelete.value[1] = false;
      if (type.value.startsWith(KViewListPlants)) {
        plantStore.value[1].find(function (el) {
          if (el !== undefined && el.unSelection) {
            showDelete.value[0] = true;
          }
        });
      } else {
        materialStore.value[1].find(function (el) {
          if (el !== undefined && el.unSelection) {
            showDelete.value[1] = true;
          }
        });
      }
    }
    function unSelect() {
      showDelete.value[0] = false;
      showDelete.value[1] = false;
      if (type.value.startsWith(KViewListPlants)) {
        plantStore.value[1].find(function (el) {
          if (el !== undefined && el.unSelection) {
            mooved(el);
          }
        });
      } else {
        materialStore.value[1].find(function (el) {
          if (el !== undefined && el.unSelection) {
            mooved(el);
          }
        });
      }
    }

    return {
      KViewList,
      availableSearch,
      selectedSearch,
      materialStore,
      plantStore,
      oldMaterialStore,
      oldPlantStore,
      visibleFull,
      visibleMaterial,
      visiblePlant,
      darktableListPlant,
      darktableListMaterial,
      currentPlant,
      currentMaterial,
      typeDarktable,
      optionType,
      optionMood,
      selectedType,
      selectedMood,
      searchAvailable,
      quantityChange,
      imageClick,
      getPreview,
      openFull,
      unSelect,
      selectChange,
      showDelete,
      searchValue,
      allPlants,
      allMaterials,
      searchPlants,
      search,
      isSelected,
      selection,
      quantity,
      type,
      mooved,
      goTo,
      i18n,
      test,
    };
  },
};
</script>
<style lang="scss" scoped>
.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
.sidebar {
  ::v-deep(.p-image) {
    img {
      width: 100%;
      height: 200px;
      border-radius: 8px;
      object-fit: cover;
    }
    .p-image-preview-indicator {
      height: 200px;
      border-radius: 8px;
    }
  }
}
.picklist {
  ::v-deep(.p-image) {
    img {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      object-fit: cover;
    }
    .p-image-preview-indicator {
      width: 50px;
      height: 50px;
      border-radius: 8px;
    }
  }
}

::v-deep(.p-picklist-source-controls) {
  display: none;
}
::v-deep(.p-picklist-target-controls) {
  display: none;
}
::v-deep(.p-inputnumber) {
  .p-inputnumber-input {
    width: 48px;
    height: 30px;
    font-size: 12px;
  }
  .p-inputnumber-button {
    height: 30px;
    width: 30px !important;
  }
}
.images-box {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  .item {
    position: relative;
    scroll-snap-align: start;
    background-color: #fff;
    border-radius: 1rem;
    margin-inline: calc(0.5 * var(--gap));
    padding: 0;
    transition: transform 200ms ease-out, filter 800ms ease-in;
    .item__title {
      height: 25px;
      width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
    &__image {
      margin: 0;
      position: relative;
      background-color: cream;
      border-radius: 0.9rem 0.9rem 0 0;
      padding-top: min(70%, 55vh);
      overflow: hidden;
      img {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    &.--active {
      filter: saturate(1.5);
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 1rem;
      box-shadow: 0.5rem 2rem 0 0rem #fff4 inset;
      filter: blur(1rem);
      z-index: 5;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0.5rem -0.1rem -0.5rem;
      border-radius: 2rem;
      filter: blur(0.3rem);
      // background-color: #0008;
      z-index: -1;
      transition: transform 200ms ease-out, filter 200ms ease-out;
    }
    &:hover {
      // Scroll snap hates this
      transform: translateY(-0.5rem);

      &::after {
        filter: blur(0.5rem);
        transform: translateY(0.3rem);
      }
    }
  }
}
.subgrid {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  transition: all 0.5s ease;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.search-bar {
  height: 55px;
  width: 800px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
  position: relative;
  background: #fff;
  border: 1px #80808054 solid;

  .input {
    border: none;
    height: 25px;
    width: 556px;
    color: #1b1b1b;
    font-size: 15px;
    outline: none;
  }

  .input:not(:placeholder-shown) + .label {
    font-size: 9px;
    top: 3px;
    color: #4279a3;
  }

  .input:focus ~ .label {
    font-size: 9px;
    top: 3px;
    color: #4279a3;
    transition: all 0.5s ease;
  }

  .label {
    color: #aaaaaa;
    position: absolute;
    top: 18px;
    margin-left: 24px;
    pointer-events: none;
    transition: all 0.5s ease;
  }

  .search-btn {
    background: #4279a3;
    border-radius: 20px;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    justify-content: center;
    cursor: pointer;
    right: 2px;
    position: absolute;
    transition: all 1s ease;
  }
}
.hidden-space {
  width: calc(100vw - 80px);
  height: 100%;
  left: 80px;
  margin: 10px 2px 10px 30px;
  grid-column: 1 / -1;
}
</style>
