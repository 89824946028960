import http from '../../services/http';
import {
    toRef,
    reactive
} from 'vue'

const store = reactive({
    materials: []
})

async function getMaterialsByMoods(id, links, value) {
    const ids = [id].concat(links || [])

    const filter = {
        Moods: {
            Moods_id: {
                id: {
                    _in: ids
                }
            }
        }
    }

    const {
        data
    } = await http.core.get(`/data/items/Inventaire_Materials?search=${value||''}&limit=1024&filter=${JSON.stringify(filter)}&fields=Moods.Moods_id.*,Partner.*,*`)
    return data
}

async function getAllMaterial() {
    return new Promise((resolve, reject) => {
        http.core.get(`/data/items/Inventaire_Materials?fields=Moods.Moods_id.*,Partner.*,*`)
            .then(({
                data
            }) => {
                store.materials = data.data
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

function getValueSearchMaterial(value) {
    return new Promise((resolve, reject) => {
        http.core.get(`/data/items/Inventaire_Materials?search=${value}&sort[]=Name&fields=Moods.Moods_id.*,Partner.*,*`)
            .then(({
                data
            }) => {
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })

}

function getMoodById(value) {
    return new Promise((resolve, reject) => {
        http.core.get(`/data/items/Moods/${value}`)
            .then(({
                data
            }) => {
                resolve(data)
            })
            .catch(err => {
                reject(err)
            })
    })

}

export function useMaterialService() {
    return {
        materials: toRef(store, 'materials'),
        getAllMaterial,
        getMaterialsByMoods,
        getValueSearchMaterial,
        getMoodById
    }
}