<template>
  <div class="switcher">
    <input
      v-model="model"
      type="radio"
      name="balance"
      :value="'opt' + label[0]"
      :id="'opt' + label[0]"
      class="switcher__input"
      :checked="model === 'opt' + label[0]"
    />
    <label
      :for="'opt' + label[0]"
      class="switcher__label"
      :style="{ color: model === 'opt' + label[0] ? '#fff' : '#000' }"
      >{{ label[0] }}</label
    >

    <input
      v-model="model"
      type="radio"
      name="balance"
      :value="'opt' + label[1]"
      :id="'opt' + label[1]"
      class="switcher__input switcher__input--opt2"
      :checked="model === 'opt' + label[1]"
    />
    <label
      :for="'opt' + label[1]"
      class="switcher__label"
      :style="{ color: model === 'opt' + label[1] ? '#fff' : '#000' }"
      >{{ label[1] }}</label
    >

    <span
      class="switcher__toggle"
      :style="{ left: model === 'opt' + label[1] ? '95px' : '' }"
    ></span>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  props: ["option", "label"],

  setup(props) {
    const model = ref("");
    model.value = `opt${props.label[0]}`;
    function test(value) {
      console.log(value);
    }
    return { model, test };
  },
};
</script>
<style lang="scss" scoped>
.switcher {
  display: inline-block;
  height: 40px;
  margin-top: 3px;
  padding: 4px;
  background: #fff;
  border-radius: 2px;
  width: 200px;
  border-radius: 30px;
  border: solid 1px #ddd;
  position: relative;
}

.switcher__input {
  display: none;
}

.switcher__label {
  float: left;
  width: 50%;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  position: inherit;
  z-index: 10;
  transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.switcher__toggle {
  position: absolute;
  float: left;
  height: 30px;
  width: 50%;
  font-size: 12px;
  line-height: 30px;
  cursor: pointer;
  background-color: #3366cc;
  border-radius: 30px;
  left: 5px;
  top: 4px;
  transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

// .switcher__input--opt2:checked ~ .switcher__toggle {
//   left: 100px;
// }
</style>
