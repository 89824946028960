<template>
  <div class="p-4">
    <div class="pt-6">
      <div class="flex flex-row justify-content-between align-items-center">
        <div>
          <div class="text-2xl mb-2 font-semibold">Before and after</div>
          <span class="text-base"
            >Add all after renders made by a paysagist
          </span>
        </div>
        <Button label="New" icon="pi pi-plus" iconPos="left" @click="addPage" />
      </div>

      <Divider />
    </div>
    <div v-if="pages && pages.length > 0" class="flex">
      <div class="flex-1 flex-column justify-content-between">
        <div class="relative">
          <div
            class="flex flex-column align-items-center"
            style="overflow-y: scroll; height: 400px"
          >
            <Dialog
              header="Select an image"
              v-model:visible="openModal"
              :style="{ width: '50vw' }"
              :modal="true"
            >
              <div class="images-box m-0 overflow-x-hidden">
                <div v-for="(image, index) in images" :key="image">
                  <!-- <img @click="setAfterImage(image)" :src="getPreview(image.Picture.id)" /> -->

                  <div class="relative">
                    <Image
                      :src="getPreview(image.Picture.id)"
                      alt="Image"
                      preview
                    />
                    <RadioButton
                      class="absolute"
                      :id="index"
                      name="image"
                      :value="image.Picture.id"
                      v-model="imageSelect"
                      style="right: -158px; top: 12px"
                    />
                  </div>
                </div>
              </div>
              <template #footer>
                <Button
                  label="No"
                  icon="pi pi-times"
                  @click="openModal = false"
                  class="p-button-text"
                />
                <Button
                  label="Yes"
                  icon="pi pi-check"
                  @click="setBefore(currentPage, imageSelect)"
                  autofocus
                />
              </template>
            </Dialog>
            <Dialog
              header="Select an image"
              v-model:visible="openModalUpload"
              :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
              :style="{ width: '30vw' }"
              :modal="true"
            >
              <div>
                <file-pond
                  name="test"
                  ref="pond"
                  label-idle="
              <i class='pi pi-cloud-upload text-indigo-500' style='font-size: 2rem'></i><br>
               Drag and drop file here<br>
              Image Only (PNG, JPG or JPEG)</span></br>
              <div class='filepond--label-action flex align-items-center justify-content-center text-base font-semibold mt-3'>Choose a file</div>"
                  accepted-file-types="image/jpeg, image/png"
                  @addfile="uploadFile"
                />
              </div>
              <template #footer>
                <Button
                  label="No"
                  icon="pi pi-times"
                  @click="openModalUpload = false"
                  class="p-button-text"
                />
                <Button
                  label="Yes"
                  :disabled="readedFile === null"
                  icon="pi pi-check"
                  @click="confirm1($event)"
                  autofocus
                />
              </template>
            </Dialog>
            <ConfirmPopup></ConfirmPopup>
            <Dialog
              header="Header"
              v-model:visible="openSplitter"
              :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
              :style="{ width: '50vw' }"
              :modal="true"
            >
              <Splitter
                :images="[
                  getPreview(currentPage.Before),
                  getPreview(currentPage.After),
                ]"
              ></Splitter>
              <template #footer>
                <Button
                  label="Close"
                  icon="pi pi-check"
                  @click="openSplitter"
                  autofocus
                />
              </template>
            </Dialog>
            <div v-for="one in pages" :key="one.id" class="w-full">
              <div class="flex align-items-center">
                <div v-if="one.Before" class="relative">
                  <div
                    class="box-data overlay flex align-items-center absolute"
                  >
                    <Button
                      label="Change"
                      @click="(openModal = true), (currentPage = one)"
                      class="p-button-rounded m-auto p-button-sm"
                    />
                  </div>
                  <img class="box-data" :src="getPreview(one.Before)" alt="" />
                </div>
                <div
                  v-else
                  @click="(openModal = true), (currentPage = one)"
                  class="before flex flex-column align-items-center justify-content-center cursor-pointer"
                >
                  <i
                    class="pi pi-image text-indigo-500"
                    style="font-size: 1.6rem"
                  ></i>
                  <span class="mt-2">Select and image</span>
                </div>

                <i
                  class="pi pi-arrow-circle-right mx-5"
                  style="font-size: 1.6rem"
                ></i>
                <div v-if="one.After" class="relative">
                  <div
                    class="box-data overlay flex align-items-center absolute"
                  >
                    <Button
                      label="Change"
                      @click="(currentPage = one), (openModalUpload = true)"
                      class="p-button-rounded m-auto p-button-sm"
                    />
                  </div>
                  <img class="box-data" :src="getPreview(one.After)" alt="" />
                </div>

                <div
                  v-else
                  @click="(currentPage = one), (openModalUpload = true)"
                  class="before flex flex-column align-items-center justify-content-center cursor-pointer"
                >
                  <i
                    class="pi pi-image text-indigo-500"
                    style="font-size: 1.6rem"
                  ></i>
                  <span class="mt-2">Click to set image</span>
                </div>

                <Button
                  icon="pi pi-eye"
                  @click="(currentPage = one), (openSplitter = true)"
                  class="p-button-rounded p-button-secondary p-button-outlined ml-8"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-outlined ml-8"
                />
              </div>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import usePreview from "../../../../compositions/usePreview";
import Splitter from "../Splitter.vue";
import { useBookService } from "../../../../compositions/services/useBookService";
import { useConfirm } from "primevue/useconfirm";

const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilePond,
    Splitter,
  },
  setup(props) {
    const { getPreview } = usePreview();
    const {
      addBeforeAfterPage,
      updateBeforeAfterPageByUpload,
      updateBeforeAfterPageByFileId,
    } = useBookService();

    const confirm = useConfirm();
    const confirm1 = (event) => {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          setAfter(currentPage.value);
          openModalUpload.value = false;
        },
        reject: () => {},
      });
    };

    const version = 1;
    const currentBook = version - 1;
    const pages = ref([]);
    const page = ref(null);
    const currentPage = ref(null);
    const myFile = ref(null);
    const readedFile = ref(null);
    const openModal = ref(false);
    const openSplitter = ref(false);
    const openModalUpload = ref(false);
    const images = ref([]);
    const imageSelect = ref();

    try {
      pages.value = JSON.parse(
        JSON.stringify(props.project.HortusBooks[currentBook].BeforeAfter)
      );
    } catch (e) {
      console.log(e);
    }

    // if (
    //   props.project &&
    //   props.project.HortusBooks &&
    //   props.project.HortusBooks.length >= currentBook &&
    //   props.project.HortusBooks[currentBook].BeforeAfter
    // ) {
    //   pages.value = JSON.parse(
    //     JSON.stringify(props.project.HortusBooks[currentBook].BeforeAfter)
    //   );
    //   // console.log(pages.value);
    // }

    onMounted(() => {
      images.value = props.project.Pictures;
    });

    const addPage = () => {
      addBeforeAfterPage(props.project.HortusCode, version)
        .then(({ data }) => {
          pages.value = data.BeforeAfter;
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const setBefore = (page, imageId) => {
      // Fake popup to select one project picture
      // console.log(imageId);
      const nbPictures = props.project.Pictures
        ? props.project.Pictures.length
        : 0;
      const idx = pages.value.indexOf(page);
      const pictId = imageId;
      updateBeforeAfterPageByFileId(
        props.project.HortusCode,
        version,
        page.id,
        "before",
        pictId
      )
        .then(() => {
          // console.log(data);
          page.Before = pictId;
          openModal.value = false;
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(nbPictures, idx, page);
    };

    const setAfter = (page) => {
      const file = myFile.value;
      // console.log(file);
      updateBeforeAfterPageByUpload(
        props.project.HortusCode,
        version,
        page.id,
        "after",
        file,
        (event) => {
          const progressInPercent = event.loaded / event.total;
          console.log(progressInPercent, event);
        }
      )
        .then(({ data }) => {
          console.log(data);
          page.After = data.fileId;
          myFile.value = null;
          readedFile.value = null;
        })
        .catch((err) => {
          console.log(err);
        });
      readedFile.value = null;
      myFile.value = null;
    };
    function uploadFile(error, file) {
      readedFile.value = null;
      const reader = new FileReader();
      myFile.value = file.file;
      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          readedFile.value = reader.result;
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file.file);
      }
    }

    return {
      pages,
      page,
      currentPage,
      openModal,
      openSplitter,
      openModalUpload,
      readedFile,
      myFile,
      images,
      imageSelect,
      confirm1,
      addPage,
      setBefore,
      setAfter,
      getPreview,
      uploadFile,
    };
  },
};
</script>
<style lang="scss" scoped>
.before {
  width: 200px;
  height: 130px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #6d6fef65;
  background-color: #6d6fef1b;
}
.box-data {
  width: 200px;
  height: 130px;
  border-radius: 5px;
  object-fit: cover;
  border: 2px solid #6d6fef65;
}
::v-deep(.filepond--root) {
  min-height: 200px;

  margin: 0 !important;
  .filepond--panel-root {
    border: 2px dashed #d2d9ee;
    background-color: #f5f8ff !important;
  }

  .filepond--credits {
    display: none;
  }
  .filepond--drop-label {
    min-height: 200px;
  }
  .filepond--label-action {
    width: 100%;
    color: white;
    text-decoration: none;
    background-color: var(--indigo-500);
    height: 40px;
    font-size: 14px;
    border-radius: 5px;
  }
}
.images-box {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  div {
    height: 200px;
    min-width: 200px;
    cursor: pointer;
  }
  ::v-deep(.p-image) {
    height: 200px;
    width: -webkit-fill-available;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      border: 2px solid var(--indigo-300);
    }
  }
}
.overlay {
  display: hidden;
  &:hover {
    display: flex !important;
    background-color: rgba(0, 0, 0, 0.5);
    .p-button {
      display: block !important;
    }
  }
  .p-button {
    display: none !important;
  }
}
</style>
