<template>
  <div class="p-4">
    <div class="pt-6">
      <div class="flex flex-row justify-content-between align-items-center">
        <div>
          <div class="text-2xl mb-2 font-semibold">Plans Renders</div>
          <span class="text-base"
            >Add all after renders plantations plans made by a paysagist
          </span>
        </div>
        <Button label="New" icon="pi pi-plus" iconPos="left" @click="addPlan" />
      </div>

      <Divider />
    </div>
    <!-- <div>
      Project {{ project.id }}
      <br />
      <input ref="fileupload" type="file" name="fileupload" />
      <div v-if="progress > 0">Uploading ... {{ progress }} %</div>
      <br />
      <hr />
      <div v-for="one in pages" :key="one.id">
        Plan {{ one.id }}
        <br />
        {{ one }}
        <br />
        <Button label="SetMedia" @click="setPlanMedia(one)" />
        <hr />
      </div>
    </div> -->
    <div v-if="pages.length > 0" class="flex">
      <div class="flex-1 flex-column justify-content-between">
        <div class="relative">
          <div
            class="flex flex-column align-items-center"
            style="overflow-y: scroll; height: 400px"
          >
            <Dialog
              header="Select an image"
              v-model:visible="openModalUpload"
              :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
              :style="{ width: '30vw' }"
              :modal="true"
            >
              <div>
                <file-pond
                  name="test"
                  ref="pond"
                  label-idle="
              <i class='pi pi-cloud-upload text-indigo-500' style='font-size: 2rem'></i><br>
               Drag and drop file here<br>
              Image only (PNG, JPG, or JPEG)</span></br>
              <div class='filepond--label-action flex align-items-center justify-content-center text-base font-semibold mt-3'>Choose a file</div>"
                  accepted-file-types="image/jpeg, image/png"
                  @addfile="uploadFile"
                />
              </div>
              <template #footer>
                <Button
                  label="No"
                  icon="pi pi-times"
                  @click="openModalUpload = false"
                  class="p-button-text"
                />
                <Button
                  label="Yes"
                  :disabled="readedFile === null"
                  icon="pi pi-check"
                  @click="setPlanMedia(currentPage), (openModalUpload = false)"
                  autofocus
                />
              </template>
            </Dialog>
            <div v-for="one in pages" :key="one.id" class="w-full">
              <div class="flex align-items-center">
                <div v-if="one.Media" class="relative">
                  <div
                    class="box-data overlay flex align-items-center absolute"
                  >
                    <Button
                      label="Change"
                      @click="(currentPage = one), (openModalUpload = true)"
                      class="p-button-rounded m-auto p-button-sm"
                    />
                  </div>
                  <img class="box-data" :src="getPreview(one.Media)" alt="" />
                </div>

                <div
                  v-else
                  @click="(currentPage = one), (openModalUpload = true)"
                  class="before flex flex-column align-items-center justify-content-center cursor-pointer"
                >
                  <i
                    class="pi pi-image text-indigo-500"
                    style="font-size: 1.6rem"
                  ></i>
                  <span class="mt-2">Click to set image</span>
                </div>

                <Button
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-secondary p-button-outlined ml-8"
                  @click="viewFile(one.Media)"
                />
                <!-- <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-outlined ml-8"
                /> -->
              </div>
              <Divider />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import usePreview from "../../../../compositions/usePreview";

import { useBookService } from "../../../../compositions/services/useBookService";
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilePond,
  },
  setup(props) {
    const { getPreview } = usePreview();
    const {
      version,
      addPlansPage,
      getPlansFromProject,
      updatePlansPageByUpload,
    } = useBookService();

    const pages = ref(null);
    const fileupload = ref(null);
    const progress = ref(0);
    const currentPage = ref(null);
    const myFile = ref(null);
    const readedFile = ref(null);
    const openModalUpload = ref(false);

    pages.value = getPlansFromProject(props.project, version);

    const addPlan = () => {
      addPlansPage(props.project.HortusCode, version)
        .then(({ data }) => {
          pages.value = data.Plans;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const setPlanMedia = (page) => {
      const file = myFile.value;
      if (!file) {
        alert("Undefined file. Try again !");
        return;
      }
      updatePlansPageByUpload(
        props.project.HortusCode,
        version,
        page.id,
        file,
        (event) => {
          progress.value = event.loaded / event.total;
          console.log(progress.value, event);
        }
      )
        .then(({ data }) => {
          progress.value = 0;
          console.log(data);
          page.Media = data.fileId;
          myFile.value = null;
          readedFile.value = null;
        })
        .catch((err) => {
          progress.value = 0;
          console.log(err);
        });
    };

    const viewFile = (file) => {
      window.open(`/core/data/assets/${file}`, "_blank");
    };

    function uploadFile(error, file) {
      readedFile.value = null;
      const reader = new FileReader();
      myFile.value = file.file;
      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          readedFile.value = reader.result;
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file.file);
      }
    }

    return {
      pages,
      fileupload,
      progress,
      currentPage,
      viewFile,
      addPlan,
      setPlanMedia,
      uploadFile,
      getPreview,
      openModalUpload,
      // setBefore,
      // setAfter
    };
  },
};
</script>
<style lang="scss" scoped>
.before {
  width: 200px;
  height: 130px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #6d6fef65;
  background-color: #6d6fef1b;
}
.box-data {
  width: 200px;
  height: 130px;
  border-radius: 5px;
  object-fit: cover;
  border: 2px solid #6d6fef65;
}
::v-deep(.filepond--root) {
  min-height: 200px;

  margin: 0 !important;
  .filepond--panel-root {
    border: 2px dashed #d2d9ee;
    background-color: #f5f8ff !important;
  }

  .filepond--credits {
    display: none;
  }
  .filepond--drop-label {
    min-height: 200px;
  }
  .filepond--label-action {
    width: 100%;
    color: white;
    text-decoration: none;
    background-color: var(--indigo-500);
    height: 40px;
    font-size: 14px;
    border-radius: 5px;
  }
}
.overlay {
  display: hidden;
  &:hover {
    display: flex !important;
    background-color: rgba(0, 0, 0, 0.5);
    .p-button {
      display: block !important;
    }
  }
  .p-button {
    display: none !important;
  }
}
</style>
