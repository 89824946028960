<template>
  <div class="p-4">
    <div class="image-box flex flex-row">
      <ScrollPanel class="col-6 p-0" style="height: 600px">
        <DataView :value="images" :layout="layout" :paginator="true" :rows="24">
          <template #header>
            <div class="grid grid-nogutter">
              <div
                class="flex flex-row align-items-center justify-content-between col-12"
              >
                <DataViewLayoutOptions v-model="layout" />
                <Button
                  @click="downloadMedia()"
                  label="Download"
                  icon="pi pi-download"
                  iconPos="right"
                />
              </div>
            </div>
          </template>
          <template #empty>No records found.</template>

          <template #list="slotProps">
            <div class="col-12 py-3" style="width: 100%">
              <div class="flex flex-row align-items-center">
                <img
                  class="image-list-item mx-3"
                  :src="getPreview(slotProps.data.Picture.id, 50)"
                />
                <div
                  class="flex flex-row justify-content-between"
                  style="width: 100%; height: 48px"
                >
                  <div class="flex flex-column">
                    <span class="text-base font-semibold mb-1">{{
                      slotProps.data.Picture.title
                    }}</span>
                    <div v-if="slotProps.data.Description">
                      <span
                        class="text-sm description-list"
                        v-if="i18n.locale.value === 'fr'"
                        >{{ slotProps.data.Description }}</span
                      >
                      <span
                        class="text-sm description-list"
                        v-else
                        :class="slotProps.data.I18n.length ? 'text-400' : ''"
                        >{{
                          slotProps.data.I18n.length > 0
                            ? slotProps.data.I18n[0].Description
                            : slotProps.data.Description
                        }}</span
                      >
                    </div>
                  </div>
                  <span class="text-sm align-self-end mr-2">{{
                    convertSize(slotProps.data.Picture.filesize)
                  }}</span>
                </div>
              </div>
            </div>
          </template>

          <template #grid="slotProps">
            <div @click="getPicture(slotProps.data)">
              <div class="item-card shadow-1">
                <div class="flex flex-column p-3">
                  <span
                    class="text-lg mb-1 font-semibold text-overflow-ellipsis surface-overlay white-space-nowrap overflow-hidden"
                    >{{ slotProps.data.Picture.title }}</span
                  >
                  <span class="text-sm">{{
                    convertSize(slotProps.data.Picture.filesize)
                  }}</span>
                </div>
                <div>
                  <img
                    :src="getPreview(slotProps.data.Picture.id, 150)"
                    style="width: 100%; height: 150px"
                  />
                </div>
                <div class="p-3" v-if="slotProps.data.Description">
                  <span
                    v-if="i18n.locale.value == 'fr'"
                    class="text-sm description"
                  >
                    {{ slotProps.data.Description }}
                  </span>
                  <span
                    :class="slotProps.data.I18n.length === 0 ? 'text-400' : ''"
                    v-else
                    class="text-sm description"
                  >
                    {{
                      slotProps.data.I18n.length > 0
                        ? slotProps.data.I18n[0].Description
                        : slotProps.data.Description
                    }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </DataView>
      </ScrollPanel>

      <Divider layout="vertical" class="m-0 p-0" />

      <div class="col-6">
        <div
          v-if="currentPicture"
          class="flex flex-column align-items-center px-4"
        >
          <div
            class="flex flex-row justify-content-between align-items-center"
            style="width: 100%"
          >
            <span class="text-lg my-4 font-semibold">
              {{ currentPicture.Picture.title }}
            </span>
            <a
              :href="`/core/data/assets/${currentPicture.Picture.id}?download`"
              target="_blank"
              :download="currentPicture.Picture.title"
            >
              <Button
                icon="pi pi-download"
                class="p-button-rounded p-button-lg p-button-text p-button-secondary"
              />
            </a>
          </div>

          <img
            @click.stop="handlePreview"
            class="detail-image"
            :src="currentPictureUrl"
          />
          <div v-if="currentPicture.Description">
            <span v-if="i18n.locale.value === 'fr'" class="text-sm mt-4">
              {{ currentPicture.Description }}
            </span>
            <span
              v-else
              :class="currentPicture.I18n.length === 0 ? 'text-400' : ''"
              class="text-sm mt-4"
            >
              {{
                currentPicture.I18n.length > 0
                  ? currentPicture.I18n[0].Description
                  : currentPicture.Description
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import usePreview from "../../../../compositions/usePreview";
import { useI18n } from "../../../../../src/i18nPlugin";
import { useToast } from "primevue/usetoast";
import { ref, onMounted } from "@vue/runtime-core";
import { v3ImgPreviewFn } from "v3-img-preview";
import { saveAs } from "file-saver";

export default {
  props: ["project"],
  setup(props) {
    const { getPreview } = usePreview();
    const i18n = useI18n();
    const toast = useToast();
    const images = ref();
    const baseUrl = ref("");
    const layout = ref("grid");
    const currentPicture = ref();
    const lang = ref(i18n.locale.value);

    const currentPictureUrl = computed(() => {
      if (currentPicture.value) {
        return getPreview(currentPicture.value.Picture.id, 400);
      }

      return "";
    });

    const handlePreview = (e) => {
      v3ImgPreviewFn(e.target.src);
    };

    function convertSize(size) {
      if (size * 0.001 < 1000) {
        return (size * 0.001).toFixed(2) + " KB";
      }
    }

    function getPicture(item) {
      currentPicture.value = item;
    }

    function downloadMedia() {
      images.value.forEach((element) => {
        var url = "/core/data/assets/" + element.Picture.id + "?download";
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            saveAs(blob, element.Picture.filename_download);
          })
          .catch((err) =>
            toast.add({
              severity: "error",
              summary: "Error",
              detail: err.message,
              life: "3000",
            })
          );
      });
    }

    onMounted(() => {
      baseUrl.value = window.location.host;
      images.value = props.project.Pictures;
      currentPicture.value = images.value[0];
    });

    return {
      getPreview,
      convertSize,
      getPicture,
      handlePreview,
      downloadMedia,
      currentPicture,
      currentPictureUrl,
      baseUrl,
      images,
      layout,
      lang,
      i18n,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.item-card {
  height: 300px;
  max-width: 255px;
  border-radius: 8px;
  cursor: pointer;
}
.image-list-item {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}
.description-list {
  display: -webkit-box;
  max-width: 360px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.description {
  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.detail-image {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  cursor: zoom-in;
  object-fit: cover;
}
:deep(.p-dataview-grid) {
  .p-dataview-content {
    padding: 10px;
    .grid {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
}
:global(section .icon) {
  width: 40px !important;
  height: 40px !important;
}
</style>
