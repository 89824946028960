<template>
  <div class="card">
    <div class="flex flex-row justify-content-between my-3">
      <span class="text-3xl font-semibold align-self-center"
        >Project management</span
      >
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          type="text"
          v-model="searchValue"
          @change="loadData(searchValue)"
          placeholder="Search"
        />
      </span>
    </div>
    <div class="relative">
      <TabView ref="tabview" v-model:activeIndex="active">
        <TabPanel>
          <template #header>
            <Icon icon="ant-design:project-filled" width="20" height="20" />
            <span class="ml-2">To Do</span>
          </template>
          <div
            v-if="optionValue.value === 'column'"
            class="overflow-scroll relative box px-3"
            style="height: 700px"
          >
            <div class="flex" style="width: 1800px">
              <div
                v-for="state in status"
                :key="state"
                class="flex flex-column surface-200 border-round p-2 m-2"
                style="width: 290px"
              >
                <div
                  class="sticky box-header border-round px-3 py-2 text-white text-lg font-semibold flex justify-content-between align-items-center"
                  :style="{ backgroundColor: state.color, width: '270px' }"
                >
                  <span>{{ i18n.$t(state.name) }}</span>
                  <span
                    class="py-2 px-3 border-round text-white text-sm"
                    style="background-color: #ffffff36"
                    >{{ state.list.length }}</span
                  >
                </div>
                <div
                  class="bg-white w-full mt-2 border-round p-3 cursor-pointer"
                  v-for="el in state.list"
                  :key="el.HortusCode"
                  @click="$router.push(`/projects/${el.id}`)"
                >
                  <div class="flex flex-column">
                    <div class="flex flex-row justify-content-between">
                      <span class="text-2xl font-semibold">
                        {{ el.HortusCode }}
                      </span>
                      <span class="text-sm text-right">
                        {{ formatDate(el.date_created) }}
                        <br />
                        <span class="text-sm" v-if="el.Mood">
                          {{ el.Mood.Name }}
                        </span>
                      </span>
                    </div>

                    <div class="mt-9">
                      <span class="text-lg" v-if="el.HortusClient">
                        {{ el.HortusClient.Username }}
                      </span>
                    </div>
                    <div v-if="el.Comment">
                      <span
                        class="ellipsis my-3 text-base"
                        v-if="i18n.locale.value == 'fr'"
                      >
                        {{ el.Comment }}
                      </span>
                      <span
                        class="ellipsis my-3 text-base"
                        :class="el.Translations.length === 0 ? 'text-400' : ''"
                        v-else
                        >{{
                          el.Translations.length === 0
                            ? el.Comment
                            : el.Translations[0].Comment
                        }}</span
                      >
                    </div>
                    <Divider></Divider>
                    <div
                      class="flex flex-row justify-content-between align-items-center"
                    >
                      <div class="flex flex-row">
                        <div class="mr-3">
                          <i class="pi pi-image" style="font-size: 16px"></i>
                          <span class="ml-1">{{ el.Pictures.length }}</span>
                        </div>
                        <div class="mr-3">
                          <i class="pi pi-file" style="font-size: 16px"></i>
                          <span class="ml-1">{{ el.Documents.length }}</span>
                        </div>
                      </div>
                      <span
                        class="text-sm flex flex-row align-items-center"
                        v-if="el.LandscaperDeadline"
                        :style="{
                          color:
                            elapsedTime(el.LandscaperDeadline) < 5 &&
                            elapsedTime(el.LandscaperDeadline) >= 3
                              ? '#ff9800'
                              : elapsedTime(el.LandscaperDeadline) < 3 &&
                                elapsedTime(el.LandscaperDeadline) >= 1
                              ? '#ff1800'
                              : '#aa2e25',
                        }"
                      >
                        <Icon
                          icon="healthicons:i-schedule-school-date-time-outline"
                          width="26"
                          height="26"
                          :color="
                            elapsedTime(el.LandscaperDeadline) < 5 &&
                            elapsedTime(el.LandscaperDeadline) >= 3
                              ? '#ff9800'
                              : elapsedTime(el.LandscaperDeadline) < 3 &&
                                elapsedTime(el.LandscaperDeadline) >= 1
                              ? '#ff1800'
                              : '#aa2e25'
                          "
                        />
                        {{
                          elapsedTime(el.LandscaperDeadline) >= 0
                            ? i18n.$t("late")
                            : `${elapsedTime(el.LandscaperDeadline)} ${i18n.$t(
                                "days"
                              )}`
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            v-if="optionValue.value === 'list' && otherProjects.length > 0"
            :value="otherProjects"
            :paginator="true"
            class="p-datatable-plants z-4"
            :rows="10"
            dataKey="id"
            :rowHover="true"
            v-model:selection="selectedProjects"
            v-model:filters="filters"
            filterDisplay="menu"
            :loading="loading"
            sortField="date_created"
            :sortOrder="-1"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50]"
            :currentPageReportTemplate="
              i18n.$t('d_padination', {
                first: first,
                last: last,
                totalRecords: totalRecords,
              })
            "
            responsiveLayout="scroll"
            @row-click="getDetail($event)"
          >
            <template #header>
              <div class="flex justify-content-between align-items-center">
                <h5 class="m-0"></h5>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="searchValue"
                    v-on:change="globalSearch"
                    :placeholder="i18n.$t('search')"
                  />
                </span>
              </div>
            </template>
            <template #empty> No Project found. </template>
            <template #loading> Loading projects data. Please wait. </template>
            <Column field="HortusCode" :header="i18n.$t('code')" sortable>
              <template #body="{ data }">
                <div class="flex align-items-center">
                  <span class="text-base">{{ data.HortusCode }}</span>
                </div>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by code"
                />
              </template>
            </Column>
            <Column
              field="HortusClient.Username"
              :header="i18n.$t('client_name')"
              sortable
              filterMatchMode="contains"
              style="min-width: 14rem"
            >
              <template #body="{ data }">
                <span class="text-base" v-if="data.HortusClient">
                  {{ data.HortusClient.Username }}</span
                >
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by client name"
                />
              </template>
            </Column>
            <Column
              field="HortusClient.Email"
              :header="i18n.$t('client_email')"
              sortable
              filterMatchMode="contains"
              style="min-width: 14rem"
            >
              <template #body="{ data }">
                <span class="text-base" v-if="data.HortusClient">
                  {{ data.HortusClient.Email }}</span
                >
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by client email"
                />
              </template>
            </Column>
            <!-- <Column field="Nothing" header="" style="min-width: 8rem">
        <template>
          <span class="text-base"></span>
        </template>
      </Column> -->
            <Column
              field="date_created"
              :header="i18n.$t('created_date')"
              sortable
            >
              <template #body="{ data }">
                <span class="text-base" v-if="data.date_created">
                  {{ formatDate(data.date_created) }}</span
                >
              </template>
            </Column>
            <Column :header="i18n.$t('status')" :showFilterMatchModes="false">
              <template #body="{ data }">
                <Chip
                  v-if="data.Status"
                  :label="data.Status"
                  :class="
                    data.Status.toLowerCase() === 'done'
                      ? 'chip-done'
                      : data.Status.toLowerCase() === 'review'
                      ? 'chip-review'
                      : data.Status.toLowerCase() === 'clientwip'
                      ? 'chip-clientwip'
                      : data.Status.toLowerCase() === 'clientdone'
                      ? 'chip-clientdone'
                      : data.Status.toLowerCase() === 'landscaperwip'
                      ? 'chip-landscaperwip'
                      : data.Status.toLowerCase() === 'landscaperdone'
                      ? 'chip-landscaperdone'
                      : 'new'
                  "
                />
              </template>
            </Column>
            <Column
              field="Updated"
              :header="i18n.$t('deadline_date')"
              sortable
              filterMatchMode="contains"
            >
              <template #body="{ data }">
                <span
                  class="text-sm flex flex-row align-items-center"
                  v-if="data.LandscaperDeadline"
                  :style="{
                    color:
                      elapsedTime(data.LandscaperDeadline) < 5 &&
                      elapsedTime(data.LandscaperDeadline) >= 3
                        ? '#ff9800'
                        : elapsedTime(data.LandscaperDeadline) < 3
                        ? '#ff1800'
                        : '',
                  }"
                >
                  {{
                    elapsedTime(data.LandscaperDeadline) > 1
                      ? i18n.$t("late")
                      : `${elapsedTime(data.LandscaperDeadline)} ${i18n.$t(
                          "days"
                        )}`
                  }}</span
                >
              </template>
            </Column>
          </DataTable>
        </TabPanel>
        <TabPanel>
          <template #header>
            <Icon icon="ic:outline-done" width="20" height="20" />
            <span class="ml-2">Done</span>
          </template>
          <DataTable
            :value="projectsDone"
            :paginator="true"
            class="p-datatable-plants z-4"
            :rows="10"
            dataKey="id"
            :rowHover="true"
            v-model:selection="selectedProjects"
            v-model:filters="filters"
            filterDisplay="menu"
            :loading="loading"
            sortField="date_created"
            :sortOrder="-1"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50]"
            :currentPageReportTemplate="
              i18n.$t('d_padination', {
                first: first,
                last: last,
                totalRecords: totalRecords,
              })
            "
            responsiveLayout="scroll"
            @row-click="getDetail($event)"
          >
            <template #header>
              <div class="flex justify-content-between align-items-center">
                <h5 class="m-0"></h5>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="searchValue"
                    v-on:change="globalSearch"
                    :placeholder="i18n.$t('search')"
                  />
                </span>
              </div>
            </template>
            <template #empty> No Project found. </template>
            <template #loading> Loading projects data. Please wait. </template>
            <Column
              field="HortusCode"
              :header="i18n.$t('code')"
              class="cursor-pointer"
              sortable
            >
              <template #body="{ data }">
                <div class="flex align-items-center">
                  <span class="text-base">{{ data.HortusCode }}</span>
                </div>
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by code"
                />
              </template>
            </Column>
            <Column
              field="HortusClient.Username"
              :header="i18n.$t('client_name')"
              sortable
              filterMatchMode="contains"
              style="min-width: 14rem"
            >
              <template #body="{ data }">
                <span class="text-base" v-if="data.HortusClient">
                  {{ data.HortusClient.Username }}</span
                >
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by client name"
                />
              </template>
            </Column>
            <Column
              field="HortusClient.Email"
              :header="i18n.$t('client_email')"
              sortable
              filterMatchMode="contains"
              style="min-width: 14rem"
            >
              <template #body="{ data }">
                <span class="text-base" v-if="data.HortusClient">
                  {{ data.HortusClient.Email }}</span
                >
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Search by client email"
                />
              </template>
            </Column>
            <!-- <Column field="Nothing" header="" style="min-width: 8rem">
        <template>
          <span class="text-base"></span>
        </template>
      </Column> -->
            <Column
              field="date_created"
              :header="i18n.$t('created_date')"
              sortable
            >
              <template #body="{ data }">
                <span class="text-base">
                  {{ formatDate(data.date_created) }}</span
                >
              </template>
            </Column>
            <Column :header="i18n.$t('status')" :showFilterMatchModes="false">
              <template #body="{ data }">
                <Chip
                  :label="data.Status"
                  :class="
                    data.Status.toLowerCase() === 'done'
                      ? 'chip-done'
                      : data.Status.toLowerCase() === 'review'
                      ? 'chip-review'
                      : data.Status.toLowerCase() === 'clientwip'
                      ? 'chip-clientwip'
                      : data.Status.toLowerCase() === 'clientdone'
                      ? 'chip-clientdone'
                      : data.Status.toLowerCase() === 'landscaperwip'
                      ? 'chip-landscaperwip'
                      : data.Status.toLowerCase() === 'landscaperdone'
                      ? 'chip-landscaperdone'
                      : 'new'
                  "
                />
              </template>
            </Column>
            <Column
              field="Updated"
              :header="i18n.$t('deadline_date')"
              sortable
              filterMatchMode="contains"
            >
              <template #body="{ data }">
                <span
                  class="text-sm flex flex-row align-items-center"
                  v-if="data.LandscaperDeadline"
                  :style="{
                    color:
                      elapsedTime(data.LandscaperDeadline) < 5 &&
                      elapsedTime(data.LandscaperDeadline) >= 3
                        ? '#ff9800'
                        : elapsedTime(data.LandscaperDeadline) < 3
                        ? '#ff1800'
                        : '',
                  }"
                >
                  {{
                    elapsedTime(data.LandscaperDeadline) > 1
                      ? i18n.$t("late")
                      : `${elapsedTime(data.LandscaperDeadline)} ${i18n.$t(
                          "days"
                        )}`
                  }}</span
                >
              </template>
            </Column>
          </DataTable>
        </TabPanel>
      </TabView>
      <div class="absolute right-0" style="top: 14px">
        <div class="flex flex-row" style="height: 37px">
          <Button
            type="button"
            class="mr-4"
            icon="pi pi-angle-down"
            label="Filter"
            @click="toggle"
            iconPos="right"
            aria:haspopup="true"
            aria-controls="overlay_panel"
          />

          <OverlayPanel
            ref="op"
            appendTo="body"
            :showCloseIcon="false"
            id="overlay_panel"
          >
            <div class="flex flex-column">
              <div class="mb-2">
                <h6>{{ i18n.$t("partner") }}</h6>
                <Dropdown
                  v-model="selectedPartner"
                  :options="partners"
                  optionLabel="Name"
                  :placeholder="
                    i18n.$t('select_a') + i18n.$t('partner').toLowerCase()
                  "
                  @change="loadData(searchValue)"
                >
                  <template #value="slotProps">
                    <div
                      class="partner-item flex flex-row align-items-center"
                      v-if="slotProps.value"
                    >
                      <img
                        v-if="slotProps.value.Logo !== '*'"
                        :src="getPreview(slotProps.value.Logo)"
                      />
                      <div>{{ slotProps.value.Name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="partner-item" v-if="slotProps.value">
                      <img
                        v-if="slotProps.value.Logo !== '*'"
                        :src="getPreview(slotProps.value.Logo)"
                      />
                      <div>{{ slotProps.value.Name }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="mb-2">
                <h6>{{ i18n.$t("style") }}</h6>
                <Dropdown
                  v-model="selectedMood"
                  :options="moods"
                  :optionLabel="i18n.locale.value === 'en' ? 'en' : 'fr'"
                  :placeholder="
                    i18n.$t('select_a') + i18n.$t('style').toLowerCase()
                  "
                  @change="loadData(searchValue)"
                />
              </div>
            </div>
          </OverlayPanel>
          <SelectButton
            v-if="active === 0"
            @change="test(active)"
            v-model="optionValue"
            :options="options"
          >
            <template #option="slotProps">
              <i :class="slotProps.option.icon"></i>
            </template>
          </SelectButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useProcess } from "../../compositions/useProcess";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { useProjectService } from "../../compositions/services/useProjectService";
import { useI18n } from "../../../src/i18nPlugin";
import router from "@/router";
import usePreview from "../../compositions/usePreview";

export default {
  setup() {
    const { getPreview } = usePreview();
    const toast = useToast();
    const currentDate = new Date();
    const selectedProjects = ref([]);
    const projectsDone = ref([]);
    const otherProjects = ref([]);
    const selectedPartner = ref({ Name: "*", Logo: "*", id: "*" });
    const selectedStatus = ref("Done");
    const selectedMood = ref({
      en: "*",
      fr: "*",
      id: "*",
    });
    const currentProject = ref();
    const op = ref(null);
    const searchValue = ref("");
    const filters = ref({
      HortusCode: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "client.Username": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      "client.Email": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    const loading = ref(true);
    const i18n = useI18n();
    const status = ref([
      { name: "new", color: "#D69900", list: [] },
      { name: "client:wip", color: "#7F2DE9", list: [] },
      { name: "client:done", color: "#00A5D9", list: [] },
      { name: "landscaper:wip", color: "#3BC057", list: [] },
      { name: "landscaper:done", color: "#C01AB5", list: [] },
      { name: "review", color: "#607D8B", list: [] },
    ]);
    const {
      partners,
      projects,
      moods,
      getValueSearch,
      getFilteredAll,
      getPartners,
      getMoods,
    } = useProjectService();
    const options = ref([
      { icon: "pi pi-bars", value: "list" },
      { icon: "pi pi-th-large", value: "column" },
    ]);
    const optionValue = ref({ icon: "pi pi-th-large", value: "column" });
    const active = ref(0);
    const toggle = (event) => {
      op.value.toggle(event);
    };

    function isRunningRow(data) {
      if (data.hasError) {
        return "has-error";
      }
      return data.state === "running" ? "is-running" : null;
    }
    function globalSearch() {
      loading.value = true;
      getValueSearch(searchValue.value)
        .then(() => {
          router.push(`?search=${searchValue.value}`);
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }
    function getFiltered() {
      loading.value = true;
      getFilteredAll(
        selectedPartner.value.id,
        selectedStatus.value,
        selectedMood.value.id
      )
        .then(() => {})
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }
    function getDetail(event) {
      router.push(`/projects/${event.data.id}`);
    }
    const formatDate = (value) => {
      return value.toLocaleDateString(i18n.locale.value, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };
    function elapsedTime(date) {
      const diff = new Date(date) - new Date();

      const intervals = {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24,
      };

      const days = Math.ceil(diff / intervals.day);
      return days;
    }

    function loadData(search) {
      getFilteredAll(
        selectedPartner.value.id,
        "done",
        selectedMood.value.id,
        search
      )
        .then(() => {
          projectsDone.value = projects.value;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          });
        })
        .finally(() => {
          loading.value = false;
        });

      status.value.forEach((el) => {
        getFilteredAll(
          selectedPartner.value.id,
          el.name,
          selectedMood.value.id,
          search
        )
          .then(() => {
            el.list = projects.value;
            projects.value.forEach((obj) => {
              otherProjects.value.push(obj);
            });
          })
          .catch(() => {});
      });
    }

    getPartners();
    getMoods();

    loadData("");

    function test(value) {
      console.log(value);
    }

    return {
      op,
      i18n,
      selectedProjects,
      currentDate,
      status,
      partners,
      moods,
      projectsDone,
      otherProjects,
      options,
      optionValue,
      active,
      toggle,
      isRunningRow,
      globalSearch,
      ...useProcess(),
      test,
      loadData,
      projects,
      selectedPartner,
      selectedStatus,
      selectedMood,
      searchValue,
      currentProject,
      filters,
      loading,
      getPreview,
      getDetail,
      getFiltered,
      formatDate,
      elapsedTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.img_header {
  width: 300px;
  height: 300px;
  margin-bottom: -80px;
  margin-top: -76px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box {
  .box-header {
    position: -webkit-sticky;
    position: sticky;
    top: 8px;
    z-index: 10;
  }
  .next-slide {
    top: 0;
    right: -13px;
    filter: blur(20px);
    visibility: hidden;
    transition: visibility 20ms;
  }
  &:hover {
    .next-slide {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 14px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

::v-deep(.is-running) {
  background-color: rgba(19, 160, 0, 0.15) !important;
}
::v-deep(.has-error) {
  background-color: rgba(160, 0, 0, 0.137) !important;
}

::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-dropdown {
  min-width: 200px;
}
.p-chip {
  border-radius: 3px;
  font-size: 12px;
}

.chip-clientwip {
  background: var(--pink-200);
  color: white;
}
.chip-clientdone {
  background: var(--pink-400);
  color: white;
}
.chip-landscaperwip {
  background: var(--orange-300);
  color: white;
}
.chip-landscaperdone {
  background: var(--orange-500);
  color: white;
}
.chip-review {
  background: var(--blue-600);
  color: white;
}
.chip-done {
  background: var(--green-600);
  color: white;
}

.partner-item {
  img {
    width: 18px;
    height: 18px;
    margin-right: 0.5rem;
  }
}
</style>
