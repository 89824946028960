<template>
  <div class="detail-contain">
    <div v-if="isProjectReady" class="flex flex-column">
      <div class="mx-4 flex flex-row align-items-center justify-content-between">
        <h2 class="my-6">
          {{ i18n.$t("project_details") }} {{ project.HortusCode }}
          <h5>
            {{ project.HortusClient ? project.HortusClient.Username : "---" }}
          </h5>
        </h2>
        <div>
          <span class="p-buttonset">
            <Button
              label="Build PDF"
              icon="pi pi-cog"
              class="p-button-outlined"
              @click="print()"
            ></Button>
            <Button
              v-if="isLandscaper"
              label="I am done"
              icon="pi pi-check"
              class="p-button-secondary"
              @click="dialog = true"
            />
            <Dialog
              :modal="true"
              header="Recapitulation validation"
              v-model:visible="dialog"
              :style="{ width: '30vw' }"
            >
              <ul>
                <li>
                  <Icon
                    v-if="project.TodoList.length == validatedtask"
                    class="mr-2"
                    icon="heroicons-solid:check-circle"
                    color="#3d8d40"
                    width="24"
                    height="24"
                  />
                  <Icon
                    v-else
                    class="mr-2"
                    icon="heroicons-solid:x-circle"
                    color="#db3c30"
                    width="24"
                    height="24"
                  />

                  {{
                    validatedtask + "/" + project.TodoList.length + " tasks are completed"
                  }}
                </li>
                <li>
                  <Icon
                    v-if="
                      project.HortusBooks[0] &&
                      project.HortusBooks[0].BeforeAfter.length > 0
                    "
                    class="mr-2"
                    icon="heroicons-solid:check-circle"
                    color="#3d8d40"
                    width="24"
                    height="24"
                  />
                  <Icon
                    v-else
                    class="mr-2"
                    icon="heroicons-solid:x-circle"
                    color="#db3c30"
                    width="24"
                    height="24"
                  />
                  There is at less one Before/after
                </li>
                <li>
                  <Icon
                    v-if="
                      project.HortusBooks[0] && project.HortusBooks[0].Plans.length > 0
                    "
                    class="mr-2"
                    icon="heroicons-solid:check-circle"
                    color="#3d8d40"
                    width="24"
                    height="24"
                  />
                  <Icon
                    v-else
                    class="mr-2"
                    icon="heroicons-solid:x-circle"
                    color="#db3c30"
                    width="24"
                    height="24"
                  />
                  There is at less one plan
                </li>
                <li>
                  <Icon
                    v-if="
                      project.HortusBooks[0] &&
                      project.HortusBooks[0].PlantsShoppingList.length > 0
                    "
                    class="mr-2"
                    icon="heroicons-solid:check-circle"
                    color="#3d8d40"
                    width="24"
                    height="24"
                  />
                  <Icon
                    v-else
                    class="mr-2"
                    icon="heroicons-solid:x-circle"
                    color="#db3c30"
                    width="24"
                    height="24"
                  />
                  There is at less an element(Plant/Material) in the shoppingList
                </li>
                <li class="flex flex-column align-content-end" style="margin-top: 40px">
                  <span v-if="completedState != 100" class="mb-2 align-self-end"
                    >All the element listed before must be respect:
                  </span>
                  <span class="mb-2 align-self-end" v-else
                    >You can close the project:
                  </span>
                  <div class="flex flex-column align-self-end">
                    <ProgressBar
                      :showValue="false"
                      :value="completedState"
                      style="height: 0.6em; width: 10em; margin-left: 10px"
                    />
                    <span class="flex align-self-end pr-2"
                      >{{ (completedState / 100) * 4 }}/4 Completed</span
                    >
                  </div>
                </li>
              </ul>

              <template #footer>
                <Button
                  v-if="completedState == 100"
                  label="Completed"
                  icon="pi pi-check"
                  @click="dialog = false"
                  autofocus
                />
              </template>
            </Dialog>
          </span>
        </div>
      </div>

      <TabView
        class="tabview-custom"
        :activeIndex="activeIndex"
        @tab-click="changeTab($event)"
      >
        <TabPanel v-for="tab in tabs" :key="tab.text">
          <template #header>
            <i :class="tab.icon"></i>
            <span class="ml-2">{{ i18n.$t(`${tab.text}`) }}</span>
            <Badge v-if="tab.newMessage" value="!" severity="danger" class="ml-2"></Badge>
          </template>
          <component
            :ref="tab.id === 'comments' ? 'commentComponent' : ''"
            v-if="project.id"
            :is="tab.component"
            :project="project"
          ></component>
        </TabPanel>
      </TabView>
    </div>
    <div v-else>Loading project</div>
  </div>
</template>

<script>
import { ref, shallowRef } from "@vue/runtime-core";
import { useProjectService } from "@compositions/services/useProjectService";
import { useBookService } from "@compositions/services/useBookService";
import { useAuth } from "@compositions/useAuth";

import router from "@/router";
import { useI18n } from "../../../src/i18nPlugin";
import Summary from "./components/tabs/Summary.vue";
import Media from "./components/tabs/Media.vue";
import ShoppingList from "./components/tabs/ShoppingList.vue";
import Documents from "./components/tabs/Documents.vue";
import Renders from "./components/tabs/Renders.vue";
import BeforeAfter from "./components/tabs/BeforeAfter.vue";
import Comments from "./components/tabs/Comments.vue";
import Plans from "./components/tabs/Plans.vue";
import { useToast } from "primevue/usetoast";

export default {
  components: [
    Summary,
    Media,
    ShoppingList,
    Documents,
    Renders,
    BeforeAfter,
    Plans,
    Comments,
  ],
  setup() {
    const toast = useToast();
    const i18n = useI18n();
    const images = ref([]);
    const validatedtask = ref(0);
    const { project, isProjectReady, getById } = useProjectService();
    const { getHortusBookUrl } = useBookService();
    const { isLandscaper } = useAuth();
    const activeIndex = ref(0);
    const commentComponent = ref(null);
    const dialog = ref(false);
    const completedState = ref(0);
    const tabs = ref([
      {
        id: "summary",
        icon: "pi pi-align-left",
        text: "summary",
        component: shallowRef(Summary),
      },
      {
        id: "media",
        icon: "pi pi-images",
        text: "media",
        component: shallowRef(Media),
      },
      {
        id: "documents",
        icon: "pi pi-file",
        text: "documents",
        component: shallowRef(Documents),
      },
      {
        id: "shopping list",
        icon: "pi pi-shopping-bag",
        text: "shopping_list",
        component: shallowRef(ShoppingList),
      },
      {
        id: "before-after",
        icon: "pi pi-box",
        text: "beforeAfter",
        component: shallowRef(BeforeAfter),
      },
      {
        id: "plans",
        icon: "pi pi-box",
        text: "plans",
        component: shallowRef(Plans),
      },
      {
        id: "comments",
        icon: "pi pi-comments",
        text: "comments",
        component: shallowRef(Comments),
        newMessage: false,
      },
    ]);

    setInterval(function () {
      if (commentComponent.value.messageCount < commentComponent.value.comments.length) {
        tabs.value[6].newMessage = true;
        commentComponent.value.messageCount = commentComponent.value.comments.length;
      }
    }, 3000);

    getById(router.currentRoute.value.params.id)
      .then(({ data }) => {
        if (project.value.TodoList.length == validatedtask) {
          completedState.value = completedState.value + (1 / 4) * 100;
        }
        if (
          project.value.HortusBooks[0] &&
          project.value.HortusBooks[0].BeforeAfter.length > 0
        ) {
          completedState.value = completedState.value + (1 / 4) * 100;
        }
        if (
          project.value.HortusBooks[0] &&
          project.value.HortusBooks[0].Plans.length > 0
        ) {
          completedState.value = completedState.value + (1 / 4) * 100;
        }
        if (
          project.value.HortusBooks[0] &&
          project.value.HortusBooks[0].PlantsShoppingList.length > 0
        ) {
          completedState.value = completedState.value + (1 / 4) * 100;
        }
        if (data.Location) {
          data.TodoList.forEach((el) => {
            if (el.IsValid) {
              validatedtask.value++;
            }
          });
          location.value = {
            lat: parseFloat(data.Location.coordinates[1]),
            lng: parseFloat(data.Location.coordinates[0]),
          };
        }
      })
      .catch((err) =>
        toast.add({
          severity: "error",
          summary: "Error",
          detail: err.message,
          life: "3000",
        })
      );

    tabs.value.find(function (item, i) {
      if (item.id === router.currentRoute.value.hash.substring(1)) {
        activeIndex.value = i;
      }
    });

    function changeTab(event) {
      router.push({
        path: "",
        hash: `#${i18n.$t(tabs.value[event.index].text).toLowerCase()}`,
      });
      if (event.index === 6) {
        setTimeout(() => {
          document.getElementById("msg-zone").scrollTop = document.getElementById(
            "msg-zone"
          ).scrollHeight;
        }, "5");
        tabs.value[6].newMessage = false;
      }
    }

    const print = () => {
      window.open(getHortusBookUrl(project.value.HortusCode, "1"), "_blank");
    };

    return {
      i18n,
      images,
      tabs,
      validatedtask,
      isProjectReady,
      activeIndex,
      changeTab,
      project,
      commentComponent,
      completedState,
      print,
      dialog,
      isLandscaper,
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-contain {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: white;
}
.tabview-custom {
  i,
  span {
    vertical-align: middle;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-tabview p {
  line-height: 1.5;
  margin: 0;
}
::v-deep(.p-tabview-panels) {
  padding: 0 !important;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin fadeInList() {
  opacity: 0;

  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      animation-delay: (1s * $i);
      animation-name: fadeInAnimation;
    }
  }
  &:last-child {
    animation-delay: (1s * 5);
    animation-name: slideInAnimation;
  }
}

ul {
  margin: 30px auto;
  padding: 0;
  list-style-type: none;
  width: 400px;

  li {
    width: 100%;
    height: 40px;
    margin: 0 0 5px 0;
    padding: 0 10px;
    display: flex;
    align-items: center;

    @include fadeInList();
  }
}
</style>
