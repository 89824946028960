<template>
  <div class="p-4">
    <div class="pt-6">
      <div class="flex flex-row justify-content-between align-items-center">
        <div>
          <div class="text-2xl mb-2 font-semibold">Files and assets</div>
          <span class="text-base"
            >Documents like cadaster that have been uploaded as part of this
            project</span
          >
        </div>
      </div>

      <Divider />
    </div>
    <div class="database-box shadow-1">
      <div
        v-if="false"
        class="flex flex-row justify-content-between align-items-center p-4"
      >
        <div>
          <div class="text-lg mb-1 font-semibold">Attached files</div>
          <span class="text-sm text-indigo-600">
            Files and assets that have been attached to this project.
          </span>
        </div>
        <Button
          icon="pi pi-ellipsis-v"
          class="p-button-rounded p-button-text p-button-secondary"
          width="24"
          height="24"
        />
      </div>

      <DataTable
        :value="files"
        :paginator="true"
        :rows="25"
        dataKey="id"
        :rowHover="true"
        v-model:selection="selectedFiles"
        :loading="loading"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100]"
        :currentPageReportTemplate="
          i18n.$t('d_padination', {
            first: first,
            last: last,
            totalRecords: totalRecords,
          })
        "
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="flex justify-content-between align-items-center">
            <h5 class="m-0"></h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="searchValue"
                v-on:change="globalSearch"
                :placeholder="i18n.$t('search')"
              />
            </span>
          </div>
        </template>
        <template #empty> No Project found. </template>
        <template #loading> Loading projects data. Please wait. </template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="title" :header="i18n.$t('filename')" sortable>
          <template #body="{ data }">
            <div class="flex align-items-center">
              <Avatar
                v-if="data.type.split('/')[0] === 'image'"
                icon="pi pi-image"
                class="mr-2"
                style="background-color: #d0e9ff; color: #5083ba"
                shape="circle"
              />
              <Avatar
                v-if="data.type.split('/')[0] === 'application'"
                icon="pi pi-file"
                class="mr-2"
                style="background-color: rgb(199 214 246); color: #535cc1"
                shape="circle"
              />
              <div class="flex flex-column">
                <span class="text-sm font-semibold">{{
                  data.filename_download
                }}</span>
                <span class="text-sm">{{ convertSize(data.filesize) }}</span>
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="filesize"
          :header="i18n.$t('filesize')"
          sortable
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <span class="text-base"> {{ convertSize(data.filesize) }}</span>
          </template>
        </Column>
        <Column
          field="uploaded_on"
          :header="i18n.$t('uploaded_date')"
          sortable
          style="min-width: 14rem"
        >
          <template #body="{ data }">
            <span class="text-base"> {{ formatDate(data.uploaded_on) }}</span>
          </template>
        </Column>
        <Column
          field="uploaded_by.email"
          :header="i18n.$t('uploaded_by')"
          sortable
        >
          <template #body="{ data }">
            <div class="flex flex-column">
              <span class="text-sm font-semibold">{{
                data.uploaded_by.first_name + " " + data.uploaded_by.last_name
              }}</span>
              <span class="text-sm">{{ data.uploaded_by.email }}</span>
            </div>
          </template>
        </Column>
        <Column field="action" header="">
          <template #body="{ data }">
            <a
              :href="'http://' + baseUrl + '/core/data/assets/' + data.id"
              target="_blank"
            >
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-text p-button-secondary"
              />
            </a>
            <a
              :href="
                'http://' +
                baseUrl +
                '/core/data/assets/' +
                data.id +
                '?download'
              "
              target="_blank"
              :download="data.title"
            >
              <Button
                icon="pi pi-download"
                class="p-button-rounded p-button-text p-button-secondary"
              />
            </a>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { useProjectService } from "../../../../compositions/services/useProjectService";
import { useToast } from "primevue/usetoast";

import { useI18n } from "../../../../i18nPlugin";
import { ref } from "@vue/reactivity";

export default {
  props: ["project"],
  setup(props) {
    const toast = useToast();
    const files = ref([]);
    const i18n = useI18n();
    const selectedFiles = ref([]);
    const loading = ref(true);
    const searchValue = ref("");
    const baseUrl = ref("");
    const { getFileById } = useProjectService();

    const formatDate = (value) => {
      return new Date(value).toLocaleDateString(i18n.locale.value, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };
    function globalSearch() {
      loading.value = false;
    }
    function convertSize(size) {
      if (size * 0.001 < 1000) {
        return (size * 0.001).toFixed(2) + " KB";
      }
    }
    baseUrl.value = window.location.host;
    props.project.Documents.forEach((element) => {
      getFileById(element.directus_files_id)
        .then(({ data }) => {
          files.value.push(data);
        })
        .catch((err) =>
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err.message,
            life: "3000",
          })
        );
    });
    loading.value = false;

    return {
      selectedFiles,
      formatDate,
      searchValue,
      loading,
      baseUrl,
      files,
      globalSearch,
      convertSize,
      i18n,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.filepond--root) {
  min-height: 10em;
  .filepond--panel-root {
    border: 1px solid #d2d9ee;
    background-color: #f5f8ff !important;
  }

  .filepond--credits {
    display: none;
  }
  .filepond--drop-label {
    min-height: 10em;
  }
}
.database-box {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.p-button:focus,
.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-danger:enabled:focus {
  box-shadow: none;
}
</style>
