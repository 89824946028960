<template>
  <div class="grid justify-content-center p-4">
    <div class="flex flex-column col-5">
      <span class="align-self-center py-4 text-2xl font-semibold"> Overview </span>
      <div class="pt-5 flex flex-column">
        <div class="grid">
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="ci:qr-code-1" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Code</span>
              <span class="text-lg">{{ project.HortusCode }}</span>
            </div>
          </span>
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="bx:check-square" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Status</span>
              <span class="text-lg">{{ i18n.$t(project.Status) }}</span>
            </div>
          </span>
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="ic:outline-style" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Mood</span>
              <span class="text-lg">{{ mood }}</span>
            </div>
          </span>
        </div>
        <div class="grid mt-3">
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="carbon:area" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Area</span>
              <span class="text-lg">{{ project.Area }}</span>
            </div>
          </span>
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="bi:cloud-sun-fill" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Sun exposure</span>
              <span class="text-lg">{{ project.SunExposure }}</span>
            </div>
          </span>
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="carbon:soil-moisture" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Ground</span>
              <span class="text-lg">{{ project.Ground }}</span>
            </div>
          </span>
        </div>
        <div class="grid mt-3">
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="fluent:target-arrow-16-regular" width="36" height="36" />
            <div class="flex flex-column ml-2">
              <span class="text-xs">Goal</span>
              <span class="text-lg">{{ project.Goal }}</span>
            </div>
          </span>
          <span class="col-4 flex flex-row align-items-center">
            <Icon icon="lucide:type" width="36" height="36" />
            <div class="flex flex-column ml-2" v-if="project.Type">
              <span class="text-xs">Type</span>
              <span class="text-lg">{{ project.Type }}</span>
            </div>
          </span>
          <span class="col-4 flex flex-row align-items-center">
            <Icon
              icon="healthicons:i-schedule-school-date-time-outline"
              width="36"
              height="36"
              :color="
                elapsedTime(project.LandscaperDeadline) < 5 &&
                elapsedTime(project.LandscaperDeadline) >= 3
                  ? '#ff9800'
                  : elapsedTime(project.LandscaperDeadline) < 3 &&
                    elapsedTime(project.LandscaperDeadline) >= 0
                  ? '#ff1800'
                  : '#aa2e25'
              "
            />
            <div class="flex flex-column ml-2">
              <span class="text-xs">DeadLine</span>
              <span
                class="text-lg"
                v-if="project.LandscaperDeadline"
                :style="{
                  color:
                    elapsedTime(project.LandscaperDeadline) < 5 &&
                    elapsedTime(project.LandscaperDeadline) >= 3
                      ? '#ff9800'
                      : elapsedTime(project.LandscaperDeadline) < 3 &&
                        elapsedTime(project.LandscaperDeadline) >= 1
                      ? '#ff1800'
                      : '#aa2e25',
                }"
              >
                {{
                  new Date(project.LandscaperDeadline).toLocaleDateString("en-US")
                }}</span
              >
              <span
                :style="{ color: '#aa2e25' }"
                v-if="elapsedTime(project.LandscaperDeadline) < 1"
                class="text-xs"
                >{{ i18n.$t("late") }}</span
              >
            </div>
          </span>
        </div>
        <div>
          <div class="flex flex-column mt-4">
            <span class="text-sm mb-2">Address</span>
            {{ project.Address }}
          </div>
          <!-- <div class="grid mt-3">
          <span class="col-6 flex flex-row align-items-center">
            <img :src="getPreview(project.Partner.Logo)" style="width:80px"/>
          </span>
        </div> -->
        </div>
        <div class="flex flex-column mt-4">
          <span class="text-sm mb-2">Comment</span>
          <span class="text-base">{{ comment }}</span>
        </div>
        <!-- <div class="grid mt-3">
        <span class="col-6 flex flex-row align-items-center">
          <img :src="getPreview(project.Partner.Logo)" style="width:80px"/>
        </span>
      </div> -->
      </div>
      <hr />
      <GMapMap :center="location" :zoom="15" map-type-id="roadmap">
        <GMapMarker
          :position="location"
          :clickable="true"
          :draggable="true"
          :icon="{
            url: 'https://img.icons8.com/fluency/344/house-with-a-garden.png',
            scaledSize: { width: 50, height: 50 },
          }"
          @click="center = location"
        >
          <GMapInfoWindow>
            <div>{{ project.Address }}</div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </div>
    <div class="col-1">
      <Divider layout="vertical" />
    </div>

    <div class="flex flex-column col-5">
      <div>
        <div class="flex flex-row justify-content-between my-4">
          <span class="align-self-center text-2xl font-semibold">To-Do list</span>
        </div>

        <div class="flex flex-column">
          <div v-for="todo in project.TodoList" :key="todo.id">
            <div
              class="mb-3 py-4 px-3 border-solid border-200 relative"
              v-if="!todo.IsValid"
              style="border-radius: 8px"
              @mouseenter="todo.cursorOn = true"
              @mouseleave="todo.cursorOn = false"
            >
              <transition
                v-if="todo.cursorOn"
                leave-active-class="animate__animated animate__slideOutUp"
                enter-active-class="animate__animated animate__slideOutDown"
                mode="out-in"
              >
                <Tag
                  v-if="todo.cursorOn && todo.IsDone && isAdmin"
                  class="absolute cursor-pointer"
                  style="right: 8px; bottom: 8px"
                  icon="pi pi-check"
                  severity="success"
                  value="validate"
                ></Tag>
              </transition>

              <Tag
                v-if="todo.ItemOption"
                :value="todo.ItemOption.TodoListItem.Category"
                style="right: 8px; top: 8px"
                class="absolute capitalize"
              ></Tag>
              <div class="flex flex-row align-items-center">
                <Checkbox
                  class="mr-5"
                  id="binary"
                  v-model="todo.IsDone"
                  @click="toggleTodo(todo)"
                  :deactivated="true"
                  :binary="true"
                />

                <div>
                  <span class="text-lg">{{ getTodoTitle(todo) }}</span
                  ><br />
                  <div
                    class="flex flex-row mt-2 align-items-center"
                    v-if="todo.ItemOption"
                  >
                    <span>{{ todo.ItemOption.TodoListItem.Name }}</span> :
                    <div
                      :key="option.Name"
                      class="ml-2 option"
                      rounded
                      v-for="option in todo.ItemOption.TodoListItem.Options"
                    >
                      {{ option.Name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="opacity-40">
        <div class="flex flex-row justify-content-between mt-4 mb-2">
          <span class="align-self-center text-xl font-bold">Completed</span>
        </div>

        <div class="flex flex-column">
          <div v-for="todo in project.TodoList" :key="todo.id">
            <div
              class="mb-3 py-4 px-3 border-solid border-200 relative"
              v-if="todo.IsValid"
              style="border-radius: 8px"
            >
              <Tag
                v-if="todo.ItemOption"
                :value="todo.ItemOption.TodoListItem.Category"
                style="right: 8px; top: 8px"
                class="absolute capitalize"
              ></Tag>

              <div class="flex flex-row align-items-center">
                <Icon
                  class="mr-5"
                  icon="akar-icons:circle-check-fill"
                  color="#6366f1"
                  width="24"
                  height="24"
                />

                <div>
                  <span class="text-lg line-through">{{ getTodoTitle(todo) }}</span
                  ><br />
                  <div
                    class="flex flex-row mt-2 align-items-center"
                    v-if="todo.ItemOption"
                  >
                    <span>{{ todo.ItemOption.TodoListItem.Name }}</span> :
                    <div
                      :key="option.Name"
                      class="ml-2 option"
                      rounded
                      v-for="option in todo.ItemOption.TodoListItem.Options"
                    >
                      {{ option.Name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, computed } from "@vue/runtime-core";
import usePreview from "../../../../compositions/usePreview";
import { useI18n } from "../../../../../src/i18nPlugin";
import { useAuth } from "@compositions/useAuth";

export default {
  props: ["project"],

  setup(props) {
    const { getPreview } = usePreview();
    const { isAdmin } = useAuth();
    const i18n = useI18n();
    const lang = ref(i18n.locale.value);
    const location = ref({ lat: 32.15, lng: 47.55 });

    console.log(isAdmin);

    const mood = computed(() => {
      try {
        if (i18n.locale.value === "en") {
          for (let idx = 0; idx < props.project.Mood.I18n.length; idx++) {
            if (props.project.Mood.I18n[idx].languages_id.includes(i18n.locale.value)) {
              return props.project.Mood.I18n[idx].Name;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      console.log(props.project);

      return props.project.Mood.Name;
    });

    const comment = computed(() => {
      try {
        if (i18n.locale.value === "en") {
          for (let idx = 0; idx < props.project.Translations.length; idx++) {
            if (
              props.project.Translations[idx].languages_code.includes(i18n.locale.value)
            ) {
              return props.project.Translations[idx].Comment;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }

      return props.project.Comment;
    });

    const getTodoTitle = (item) => {
      try {
        if (i18n.locale.value === "en") {
          for (let idx = 0; idx < item.I18n.length; idx++) {
            if (item.I18n[idx].languages_code.includes(i18n.locale.value)) {
              return item.I18n[idx].Title;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }

      return item.Title;
    };

    props.project.TodoList.forEach((el) => {
      el.cursorOn = false;
    });

    const toggleTodo = (todo) => {
      console.log(todo);
    };

    onMounted(() => {
      if (props.project.Location) {
        location.value = {
          lat: parseFloat(props.project.Location.coordinates[1]),
          lng: parseFloat(props.project.Location.coordinates[0]),
        };
      }
    });
    function elapsedTime(date) {
      const diff = new Date(date) - new Date();

      const intervals = {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24,
      };

      const days = Math.ceil(diff / intervals.day);
      return days;
    }

    return {
      i18n,
      lang,
      mood,
      comment,
      location,
      elapsedTime,
      getPreview,
      getTodoTitle,
      toggleTodo,
      isAdmin,
    };
  },
};
</script>
<style lang="scss" scoped>
.vue-map-container {
  height: 400px;
  border-radius: 8px;
  border: 2px solid var(--indigo-500);
  padding: 0 !important;
}
::v-deep(.vue-map) {
  border-radius: 8px;
}
.option {
  background-color: #eff0f4;
  color: #2d2e33;
  padding: 7px 10px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
}
</style>
